import React from "react";
import PropTypes from "prop-types";
import "common/Button/IconButton/IconButton.scss";

const IconButton = ({ Icon, name, iconFill, iconSize, className, onclick }) => {
  return (
    <button
      name={name}
      className={`jsx-button icon-button ${className}`}
      onClick = {onclick}
    >
      <Icon
        className="button-prefix"
        style={{ fontSize: iconSize }}
        sx={{ color: iconFill }}
      />
    </button>
  );
};

export default IconButton;
