import { useState } from "react";

function UserSignUp() {
    const [inputText, setInputText] = useState("");
    const handleInputChange = (event) => {
        setInputText(event.target.value);
    }
    return (
        <div>
            <input type = "text" value = {inputText} onChange={handleInputChange} />
            <h2>{inputText}</h2>
        </div>
    )
}

export default UserSignUp;