import React from "react";
import "./UserInput.scss";
import PropTypes from "prop-types";

const UserInput = ({ name, type, value, children, onChange, className }) => {
  return (
    <input
      name={name}
      type={type}
      value={value}
      className={`user-input-jsx ${className}`}
      placeholder={children}
      onChange={onChange}
    ></input>
  );
};

UserInput.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
};

export default UserInput;
