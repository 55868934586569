import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import "./HyperLink.scss";

const HyperLink = ({ linkTo, className, children, linkTarget, onClick }) => {
  return (
    <Link
      to={linkTo}
      target={linkTarget}
      className={`hyperlink-jsx ${className}`}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

HyperLink.defaultProps = {
  linkTo: "",
  children: "hyperlink",
};

export default HyperLink;
