import React from "react";
import PropTypes from "prop-types";
import "common/Button/IconWithLabelButton/IconWithLabelButton.scss";

const IconWithLabelButton = ({
  Icon,
  name,
  iconFill,
  iconSize,
  children,
  className,
  onclick,
}) => {
  return (
    <button
      name={name}
      className={`jsx-button icon-with-label-button ${className}`}
      onClick={onclick}
    >
      <Icon
        className="button-prefix"
        style={{ fontSize: iconSize }}
        sx={{ color: iconFill }}
      />
      <span className="button-content">{children}</span>
    </button>
  );
};

IconWithLabelButton.propTypes = {
  Icon: PropTypes.node.isRequired,
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default IconWithLabelButton;
