import React from 'react';
import "./Title.scss";
import CompTitle from "./TitlePages/CompTitle";
import SchoTitle from "./TitlePages/SchoTitle";
import NetwTitle from "./TitlePages/NetwTitle";


function Title({titleData}) {
  let titleInfo;
  if (titleData.eventtype === "competition") {
    titleInfo = <CompTitle titleData = {titleData}/>;
  } else if (titleData.eventtype === "scholarship") {
    titleInfo = <SchoTitle titleData = {titleData}/>;
  } else {
    titleInfo = <NetwTitle titleData = {titleData}/>;
  }
    return (
      <>
      {titleInfo}
      </>
    )
}

export default Title;