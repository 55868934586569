import React from "react";
import "./Settings.scss";

/*
    [Component Information]
canvas: a white container of all responsive HTML components
shortened-info: a top-left subcontainer of canvas with profile picture, name, and account type inside
option-panel: a selection navigator with different setting categories inside
section-subcanvas: a right subcontainer of canvas with different setting panels inside
*/
const Settings = () => {
  return (
    /* Page Wrapper */
    <main>
      <h1 className="settings-title">Account Settings</h1>
      <article className="canvas">
        <div className="settings-shortened-info">
          <div className="shortened-picture"></div>
          <div className="shortened-name">Jane Doe</div>
          <div className="shortened-account-type">Student account</div>
        </div>
        <nav className="settings-option-panel">
          <div className="selector-bar"></div>
          <li className="option-list">
            <ul className="option-item">General</ul>
            <ul className="option-item">Profile</ul>
            <ul className="option-item">Data & Privacy</ul>
            <ul className="option-item">Preferences</ul>
            <ul className="option-item">Notifications</ul>
          </li>
        </nav>
        <div className="settings-section-subcanvas">
          <section className="subcanvas-info-module">
            <div className="info-module-title">
              <h2>Basic Information</h2>
            </div>
            <div className="info-module-submodule">
              <div className="submodule-group">
                <h3>Name</h3>
                <div className="submodule-group-item">
                  <span>First name</span>
                </div>
                <div className="submodule-group-item">
                  <span>Last name</span>
                </div>
                <div className="submodule-group-item">
                  <span>Preferred name</span>
                </div>
                <span className="submodule-tag">
                  Preferred name will be displayed. Otherwise, first name is
                  used instead.
                </span>
              </div>
            </div>
          </section>
        </div>
      </article>
    </main>
  );
};

export default Settings;
