const eventContent = [
    {
        id: 0,
        eventtype: "competition",
        name: "DataFest",
        location: "University Park, PA",
        //Grad, College, Major, team, other, Additional
        eligibility: ["Both","All Colleges","All Majors","3-5","--","--"],
        timeline: [[["3/24/2023","--"],["3/26/2023","--"]],[["--","--"],["--","--"]],[["--","--"],["--","--"]]], //[Deadline,Announcement],[Deadline,Announcement],[Deadline,Announcement]
        prize: ["--","--","--"],
        host: ["ASA Data Fest at Penn State","--"],
        apply: "Submission",
        link: "https://datafest.psu.edu/",
        overview: `The American Statistical Association (ASA) DataFest™ is a celebration of data in which student teams work around the clock to find and share meaning in a large, rich, and complex dataset.
        ASA DataFest™ was founded at UCLA in 2011 when 30 students gathered for 48 intense hours to help fight crime through an analysis of every arrest record of the last 5 years as provided by Lt. Thomas Zak of the Los Angeles Police Department. ASA DataFest™ is now sponsored by the American Statistical Association and is hosted by some of the most prestigious colleges and universities worldwide.
        The Department of Statistics began hosting ASA DataFest™ at Penn State in 2015.`,
    },
    {
        id: 1,
        eventtype: "competition",
        name: "Undergraduate Poster Competition",
        location: "Remote & Onsite",
        //Grad, College, Major, team, other, Additional
        eligibility: ["Undergrad","College of Earth and Mineral Sciences","MATSE_BS / EMSC_PMAJ (intending MatSE) majors","--","--",
        "** All undergraduate seniors writing a senior thesis and Undergraduate Research Fellows (URF) are required to submit posters."],
        timeline: [[["3/15/2023","11:59PM"],["3/15/2023","11:59PM"]],[["--","--"],["--","--"]],[["--","--"],["--","--"]]],
        prize: ["up to",250,
                "First, second, and third place winners will receive awards of $250, $100, or $50, respectively. Winners will be announced on April 12." 
                ],
        host: ["College of Earth and Mineral Sciences","marcom@matse.psu.edu"],
        apply: "Submission",
        link: "https://www.matse.psu.edu/undergraduatePosterCompetition",
        overview: "--",
    },
    {
        id: 2,
        eventtype: "competition",
        name: "Materials Visualization Competition",
        location: "Remote & Onsite",
        //Grad, College, Major, team, other, Additional
        eligibility: ["Both","All Colleges","All Majors","--","--","--"],
        timeline: [[["3/15/2023","11:59PM"],["3/15/2023","11:59PM"]],[["--","--"],["--","--"]],[["--","--"],["--","--"]]],
        prize: ["up to",500,
                `Participants must have a Penn State Bursar account or payroll account to receive any monetary prizes. If a winner does not meet the criteria, they will forfeit any monetary prizes.

                A panel of judges who are professionals in materials and/or the arts will award the following:
                
                    Best of Show $500
                
                In the Visual Appeal category:
                
                    First place $250
                    Second place $100
                    Third place $50
                
                In the Imaging category of Computational Rendering:
                
                    First place $250
                    Second place $100
                    Third place $50
                
                In the Imaging category of Scientific Imaging:
                
                    First place $250
                    Second place $100
                    Third place $50
                `],
        host: ["College of Earth and Mineral Sciences","MarCom@matse.psu.edu"],
        apply: "Submission",
        link: "https://www.matse.psu.edu/MVC",
        overview: "--",
    },
    {
        id: 3,
        eventtype: "competition",
        name: "PSPA State Competition",
        location: "Various Locations",
        //Grad, College, Major, team, other, Additional
        eligibility: ["Undergrad","--","--","--","--","--"],
        timeline: [[["3/30/2023","11:59PM"],["3/30/2023","11:59PM"]],[["--","--"],["--","--"]],[["--","--"],["--","--"]]],
        prize: ["--","--","--"],
        host: ["Donald P. Bellisario College of Communications",""],
        apply: "Submission",
        link: "https://libraries.psu.edu/about/awards-scholarships-internships-and-graduate-assistantships/outstanding-undergraduate-thesis",
        overview: "State championships in student journalism competition sponsored by the Pennsylvania School Press Association and hosted by the Donald P. Bellisario College of Communications. Students who advanced from regional competitions earlier in the year will compete in a dozen different writing, design or artistic categories on the University Park campus.",
    },
    {
        id: 4,
        eventtype: "scholarship",
        name: "Scholarship for Adult Learners",
        deadline: "3/31/2023",
        //Grad, College, Major, team, other, Additional
        award: ["--",""],
        appLink: "https://adultlearner.psu.edu/finance-your-education/scholarships/",
        purpose: "GEM offers MS and Ph.D. level students an outstanding opportunity and access to dozens of the top Engineering and Science firms and Universities in the nation. The GEM Fellowship was designed to focus on promoting opportunities for individuals to enter industry at the graduate level in areas such as research and development, product development, and other high level technical careers. GEM also offers exposure to a number of opportunities to a number of opportunities in academia.",
        document: "Ph.D level students",
        source: "Internal",
        //college, major, GPA, others
        eligibility: ["All Colleges","All Majors","--",`
        Age 24 or older
        Active duty military or veteran
        Have a spouse and/or dependents
        These scholarships are available to first-time and returning adult learners. In addition to this scholarship application, you must submit the 2023–24 FAFSA if you are a United States citizen or eligible non-citizen.
        Penn State World Campus students are not eligible for these scholarships. Learn more about scholarship opportunities for World Campus students.
        Employees receiving the educational benefit are not eligible to receive these scholarships.
        `],
        apply: "Online",
        eventLinke: "https://adultlearner.psu.edu/finance-your-education/", 
        host: ["Penn State"],
        overview: "--",
    },
    {
        id: 5,
        eventtype: "scholarship",
        name: "The Bunton-Waller Scholarship",
        deadline: "4/15/2023",
        //Grad, College, Major, team, other, Additional
        award: [3200,"Up to"],
        appLink: "https://studentaid.psu.edu/apply",
        purpose: "Full-time students in resident instruction (not available for World Campus students) who have the highest financial need are considered for the Bunton-Waller Scholarship.",        
        document: "N/A",
        source: "Internal",
        //college, major, GPA, others
        eligibility: ["All Colleges","All Majors",2.5, 
        `Maintain Federal and Penn State Financial Aid Eligibility Requirements\n
        Enroll full-time (12 credits or more)\n
        Many require that students maintain a GPA of 3.0 or higher`],
        apply: "Online",
        eventLink: "https://studentaid.psu.edu/types-of-aid/scholarships/university-wide",
        host: ["Penn State Office of Student Aid"],
        overview: "--",
    },
    {
        id: 6,
        eventtype: "scholarship",
        name: "Udall Scholarships ",
        deadline: "3/3/2023",
        //Grad, College, Major, team, other, Additional
        award: [7000,"Up to"],
        appLink: "https://studentaid.psu.edu/apply",
        purpose: "The Udall Foundation seeks future leaders in environmental fields as well as Native American and Alaska Native leaders in Native American health care and Tribal public policy. Environmental fields span policy, engineering, science, education, urban planning and renewal, business, health, justice, and economics. Tribal policy includes fields related to tribal sovereignty, tribal governance, tribal law, Native American education, Native American justice, natural resource management, cultural preservation and revitalization, Native American economic development, and other areas affecting Native American communities. Native American health care includes healthcare administration, social work, medicine, and research into health conditions affecting Native American communities. Udall Scholars are given the tools necessary to become the next generation of leaders across a wide spectrum of environmental fields or Native American issues.",
        document: `An 11-question application \n
                    An 800-word essay related to the Udall Foundataion's core values \n
                    Transcripts for all college coursework\n
                    Three letters of recommendation that speak to your leadership, public service and academic achievements \n
                    Additional documentation is required for applicants in tribal public policy and health care, and for U.S. permanent residents. `,
        source: "Internal",
        //college, major, GPA, others
        eligibility: ["All Colleges","All Majors","--", "Sophomore or Junior"],
        apply: "Online",
        eventLink: "https://www.udall.gov/ourprograms/scholarship/scholarship.aspx",
        host: ["College of Engineering","Udall Foundataion"],
        overview: "--",
    },
    {
        id: 7,
        eventtype: "scholarship",
        name: "First-Year Undergraduate Student Scholarship",
        deadline: "1/8/2023",
        //Grad, College, Major, team, other, Additional
        award: [7000,"Up to"],
        appLink: "https://pennstate.qualtrics.com/jfe/form/SV_cuoq9Y1c4Z7P0zz",
        purpose: "First-Year Undergraduate Students scholarship application for the 2023-24 academic year",
        document: "--",
        source: "Internal",
        //college, major, GPA, others
        eligibility: ["College of Education","All majors in College of Education","--", "Must be a first year Student"],
        apply: "Online",
        eventLink: "https://ed.psu.edu/prospective-students/scholarships-and-financial-aid/college-education-scholarships-and-financial-aid",
        host: ["College of Education","education@psu.edu"],
        overview: "--",
    },
    {
        id: 8,
        eventtype: "scholarship",
        name: "The Federal Cyber Corps Scholarship for Service (SFS) program",
        deadline: "3/13/2023",
        //Grad, College, Major, team, other, Additional
        award: [25000,"Up to"],
        appLink: "https://docs.google.com/forms/d/e/1FAIpQLSf_tVKEDYAP1_AxEs7qIivl0EopyYKw_h5r2F4z8n7Co_yTcw/viewform",
        purpose: " First-Year Undergraduate Students scholarship application for the 2023-24 academic year",
        document: `Most current resume and/or curriculum vitae\n
        Transcripts are required to apply. Students at Penn State may supply their unofficial transcripts using the print-out from LionPath.  Transfer students (B.S.) or Graduate students (Ph.D. Students, M.S. students or M.S. Applicants) may provide photocopies of transcripts from previous institutions.\n
        A statement of purpose detailing academic, professional, and personal goals, and how participation in the Scholarship for Service Program will help achieve these goals\n
        A recent writing sample that is no more than 10 pages. Writing skills are a critical discriminator during hiring processes and will be heavily weighted during your evaluation for the SFS Scholarship\n
        Two letters of recommendation must be directly emailed from the letter writers to psucybercorps@gmail.com (arrange to have them arrive by above application DUE)
    `,
        source: "Internal",
        //college, major, GPA, others
        eligibility: ["College of Information Sciences and Technology","All majors in College of Information Sciences and Technology",3.0, 
        `receive an offer of admission to the College IST\n
        be registered as a full-time student in the College of IST for both the fall and spring semesters\n
        file the Free Application for Federal Student Aid (FAFSA) by February 15 of each calendar year
        `],
        apply: "Online",
        eventLink: "https://sfs.ist.psu.edu/scholarship-for-service-application-process/",
        host: ["College of Education","education@psu.edu"],
        overview: "--",
    },
    {
        id: 9,
        eventtype: "scholarship",
        name: "Discover Penn State Award for border states",
        deadline: "5/1/2023",
        //Grad, College, Major, team, other, Additional
        award: [26000,"Up to"],
        appLink: "https://shenango.psu.edu/tuition-financial-aid/financial-aid/discover-penn-state-award",
        purpose: "The Udall Foundation seeks future leaders in environmental fields as well as Native American and Alaska Native leaders in Native American health care and Tribal public policy. Environmental fields span policy, engineering, science, education, urban planning and renewal, business, health, justice, and economics. Tribal policy includes fields related to tribal sovereignty, tribal governance, tribal law, Native American education, Native American justice, natural resource management, cultural preservation and revitalization, Native American economic development, and other areas affecting Native American communities. Native American health care includes healthcare administration, social work, medicine, and research into health conditions affecting Native American communities. Udall Scholars are given the tools necessary to become the next generation of leaders across a wide spectrum of environmental fields or Native American issues.",
        document: `An 11-question application \n
                    An 800-word essay related to the Udall Foundataion's core values \n
                    Transcripts for all college coursework\n
                    Three letters of recommendation that speak to your leadership, public service and academic achievements \n
                    Additional documentation is required for applicants in tribal public policy and health care, and for U.S. permanent residents. `,
        source: "Internal",
        //college, major, GPA, others
        eligibility: ["Penn State Commonwealth Campus","All","2.0", 
        `Students must reside in: Delaware, Maryland, New Jersey, New York, Ohio, Virginia, and West Virginia, as well as the District of Columbia (other out-of-state residents are not eligible for this award)\n
        Recipients must be NEW undergraduate first-year or transfer students to the University\n
        Students do not have to apply for the award – they are automatically considered\n
        Award cannot be combined with the Provost’s Award\n
        Complete a minimum of 24 credits per academic year.`],
        apply: "Online",
        eventLink: "https://admissions.psu.edu/costs-aid/scholarships/discover/",
        host: ["Penn State Undergraduate Admissions",""],
        overview: "--",
    },
    {
        id: 10,
        eventtype: "network",
        name: "Westwood Professional Services Virtual Meet and Greet",
        //Coffee, resume, info, conference, career fair
        networktype: "Coffee Session",
        deadline: ["3/15/2023","10:30-11:30AM"],
        enddate: ["3/15/2023","10:30-11:30AM"],
        host: ["Bilén, Carmen Pa","cpb10@psu.edu"],
        attire: "Casual",
        college: "College of Engineering",
        location: "zoom",
        eventLink: "--",
        //college, major, others
        eligibility: ["College of Engineering","CE, EE, and ME majors","--"],
        overview: "Representatives from Westwood's HR and Power Division (Transmission, Distribution, Solar, Wind, Power Delivery, Substation) will discuss and answer questions about the company's culture, day-to-day activities, projects, and internship, co-op, and full-time job opportunities.",
        additional: "--",
    },
    {
        id: 11,
        eventtype: "network",
        name: "Global Careers Institute 2023: Launching a Global Career with Impact",
        //Coffee, resume, info, conference, career fair
        networktype: "Conference",
        deadline: ["3/17/2023","--"],
        enddate: ["3/17/2023","--"],
        host: ["Penn State","--"],
        attire: "--",
        college: "College of IST",
        location: "Penn Stater Hotel",
        eventLink: "--",
        //college, major, others
        eligibility: ["--","--","--"],
        overview: "--",
        additional: "--",
    },
    {
        id: 12,
        eventtype: "network",
        name: "PWC Middle East Tabling Event",
        //Coffee, resume, info, conference, career fair
        networktype: "Info Session",
        deadline: ["3/23/2023","--"],
        enddate: ["3/23/2023","--"],
        host: ["PWC","--"],
        attire: "--",
        college: "College of IST",
        location: "Westgate Building - 2nd Floor Hallway",
        eventLink: "--",
        //college, major, others
        eligibility: ["--","--","--"],
        overview: "--",
        additional: "--",
    },
    {
        id: 13,
        eventtype: "network",
        name: "Global Transparency: Managing Risk in a Ubiquitously Sensed World",
        //Coffee, resume, info, conference, career fair
        networktype: "Info Session",
        deadline: ["3/27/2023","--"],
        enddate: ["3/27/2023","--"],
        host: ["Plant Federal","--"],
        attire: "N/A",
        college: "College of IST",
        location: "Sutliff Auditorium - 118 Lewis Katz Building",
        eventLink: "--",
        //major, others
        eligibility: ["--","--","--"],
        overview: "--",
        additional: "--",
    },
    {
        id: 14,
        eventtype: "network",
        name: "Understanding exoplanets through Radial Velocity Observations",
        //Coffee, resume, info, conference, career fair
        networktype: "Info Session",
        deadline: ["3/13/2023",""],
        enddate: ["3/13/2023",""],
        host: ["Hannah Osborne (University College London)","--"],
        attire: "--",
        college: "Eberly College of Science",
        location: "Davey Laboratory",
        eventLink: "--",
        //college, major, others
        eligibility: ["--","--","--"],
        overview: "--",
        additional: "--",
    },
    {
        id: 15,
        eventtype: "network",
        name: "Concepts & Considerations in Caring for the Hospitalized Geriatric Trauma Patient",
        //Coffee, resume, info, conference, career fair
        networktype: "Info Session",
        deadline: ["3/16/2023","--"],
        enddate: ["3/16/2023","--"],
        host: ["College of Nursing","--"],
        attire: "--",
        college: "College of Nursing",
        location: "Online",
        eventLink: "https://www.nursing.psu.edu/calendar/list/",
        //college, major, others
        eligibility: ["--","--","--"],
        overview: "--",
        additional: "--",
    },
  ];

  export default eventContent;