import React from "react";
import Square from "common/Square/Square.jsx";
import ProfileBox from "common/ProfileBox/LogInProfileBox/LogInProfileBox.jsx";
import beyondclasslogo from "assets/brand/beyondClassLogo.svg";
import "./Template.scss";

function Template({ pageTitle, shortDescription, squareData }) {
  const onclickHandler = (name) =>
    alert(`Sorry, we will be back soon with "${name}" function!`);
  return (
    <>
      <main className="event-category">
        <section className="template-large-wrapper">
          <div className="template-second-wrapper">
            <div className="template-title-wrapper">
              <div className="eventCategory-title-content">{pageTitle}</div>
              <div className="eventCategory-description-content">
                {shortDescription}
              </div>
            </div>
            <div className="template-items-wrapper">
              {squareData.length === 0 ? (
                <div className="eventCategory-oops-wrapper">
                  <img src={beyondclasslogo} height="50rem" />
                  <div>We will be back as soon as possible!</div>
                </div>
              ) : (
                <div className="eventCategory-square-wrapper">
                  {squareData.map((event) => (
                    <Square
                      classname={event.host[0]}
                      data={event}
                      key={event.id}
                    />
                  ))}
                </div>
              )}
              <aside className="eventCategory-profilebox-wrapper">
                <ProfileBox onclick={() => onclickHandler("Profile")} />
              </aside>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

function color() {
  document.getElementById("College of Engineering").style.border =
    "1px solid red";
  document.getElementById("College of Agricultural Science").style.border =
    "1px solid green";
  document.getElementById("College of Education").style.border =
    "1px solid purple";
}

export default Template;
