import React from 'react';


function CompTitle({titleData}) {
    return (
        <div className = "title-rectangle-wrapper">
          <div className = "title-content-wrapper">
            <div className = "title-text-content">{titleData.name}</div>
            <div className = "title-requirement-wrapper">
              <div className = "requirement-wrapper">
                <div className = "requirement-title-content">Event Date: </div> 
                <span className = "requirement-input-content">{titleData.timeline[0][0][0]}</span>
              </div>
              <div className = "requirement-wrapper">
                <div className = "requirement-title-content">Location: </div> 
                <div className = "requirement-input-content">{titleData.location}</div>
              </div>
              <div className = "requirement-wrapper">
                <div className = "requirement-title-content">Prize: </div>
                <div className = "requirement-input-content">{titleData.prize[0]} ${titleData.prize[1]}</div>
              </div>
              <div className = "requirement-wrapper">
                <div className = "requirement-title-content">Team: </div>
                <div className = "requirement-input-content">{titleData.eligibility[3]} member(s)</div>
              </div>
              <div className = "requirement-wrapper">
                <div className = "requirement-title-content">Application: </div>
                <div className = "requirement-input-content">{titleData.timeline[1][0][0]}</div>
              </div>
              <div className = "requirement-wrapper">
                <div className = "requirement-title-content">Host: </div>
                <div className = "requirement-input-content">{titleData.host[0]}</div>
              </div>
            </div>
          </div>
        </div>
    )
}

export default CompTitle;