import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import beyondclasslogo from "assets/brand/beyondClassLogo.svg";
import FooterHeadings from "./FooterHeadings";
import FooterCategories from "./FooterCategories";
import { footerSection } from "./footerSection.js";
// import assets
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = () => {
  const onClick = () => {
    alert("We will be back soon! Thank you for checking :)");
  }
  return (
    <footer className="footer">
      <nav className="footer-menu-nav">
        <Link to="/">
          <img
            className="footer-logo"
            src={beyondclasslogo}
            alt="logo"
            height="20rem"
          ></img>
        </Link>
        <div className="footer-submodule">
          <h2 className="footer-title">
            Where everyone can explore the world <br />
            they desire to achieve
          </h2>
          <div className="footer-media">
            <a
              href="https://www.instagram.com/beyondclass.official/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon className="media-link" />
            </a>
            <a
              href="https://www.linkedin.com/in/beyondclassofficial"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon className="media-link" />
            </a>
          </div>
        </div>
        <div className="footer-menu">
          <FooterHeadings section={footerSection} />
          <FooterCategories section={footerSection} />
        </div>
      </nav>
      <section>
        <div className="footer-wrapper">
          <span className="footer-copyright">
            Copyright &copy; {new Date().getFullYear()} Beyond Class. All rights
            reserved
          </span>
          <a className="footer-terms-of-service" onClick={onClick}>
            Terms of Service
          </a>
          <a className="footer-privacy-policies" onClick={onClick}>
            Privacy Policies
          </a>
          <a className="footer-legals" onClick={onClick}>
            Legals
          </a>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
