import React from "react";

import "pages/Main/Main.scss";
/* mainContents */
import ProfileBox from "common/ProfileBox/LogInProfileBox/LogInProfileBox.jsx";
import Square from "common/Square/Square.jsx";
import SquareName from "pages/Main/components/SquareName/SquareName";
import tempdata from "tempdata/tempdata";

function Main() {

  const comp = { name: "Competitions", linkTo: "/competition" };
  const scho = { name: "Scholarships", linkTo: "/scholarship" };
  const corp = { name: "Corporate Events", linkTo: "/corpevent" };

  let compList = [];
  let schoList = [];
  let netwList = [];

  for (let i = 0; i < tempdata.length; i++){
    if (tempdata[i].eventtype == "competition") {
      compList.push(tempdata[i]);
    } else if (tempdata[i].eventtype == "scholarship") {
      schoList.push(tempdata[i]);
    } else if (tempdata[i].eventtype == "network") {
      netwList.push(tempdata[i]);
  }
  }

  return (
    <>
      <main id="main-page">
        <section className="searchengine-wrapper">
          <div className="searchengine-objects-wrapper"></div>
        </section>
        <section className="mainpage-large-wrapper">
          <div className="mainpage-items-wrapper">
            <article className="article-wrapper">
              <div className="competitions-wrapper">
                <SquareName category={comp} />
                <div className="competitions-square-wrapper">
                  {compList.map((item) => (
                    <Square data={item} key={item.id} />
                  ))}
                </div>
              </div>
              <div className="scholarships-wrapper">
                <SquareName category={scho} />
                <div className="scholarships-square-wrapper">
                  {schoList.map((item) => (
                    <Square data={item} key={item.id} />
                  ))}
                </div>
              </div>
              <div className="corporates-wrapper">
                <SquareName category={corp} />
                <div className="corporates-square-wrapper">
                  {netwList.map((item) => (
                    <Square data={item} key={item.id} />
                  ))}
                </div>
              </div>
            </article>
            <div className="profilebox-wrapper">
              <ProfileBox />
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Main;
