import React from "react";
import HyperLink from "common/HyperLink/HyperLink.jsx";
import "./CategoryBox.scss";

function CategoryBox() {
  return (
    <div className="categoryBox-wrapper">
      <HyperLink linkTo="/aboutus" className={"category-item"}>
        About Us
      </HyperLink>
      <HyperLink linkTo="/competition" className={"category-item"}>
        Competitions
      </HyperLink>
      <HyperLink linkTo="/scholarship" className={"category-item"}>
        Scholarships
      </HyperLink>
      <HyperLink linkTo="/network" className={"category-item"}>
        Networking Events
      </HyperLink>
    </div>
  );
}

export default CategoryBox;
