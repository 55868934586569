import React from "react";
import "./LogInProfileBox.scss";
import styled from "styled-components";
import LabelButton from "common/Button/LabelButton/LabelButton";
import IconButton from "common/Button/IconButton/IconButton";
import HyperLink from "common/HyperLink/HyperLink";
// import icons
import SettingsIcon from "@mui/icons-material/Settings";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import profilePicture from "assets/image/profilePicture.png"

const ProfilePic = styled.div`
  width: 70px;
  height: 70px;
  background: linear-gradient(
    90deg,
    rgba(252, 158, 158, 1) 0%,
    rgba(255, 202, 109, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  border-radius: 15px;
`;

function LogInProfileBox({onclick}) {
  const profileInfo = {
    name: "Blue White",
    SemStanding: "5th Semester Standing",
    Profile: [
      "Data Science Engineering (B.S.)",
      "ENTI, New Ventures(minor)",
    ],
    Involvement: [
      "Snowboarding Club Member",
      "THON Chair of Pi Beta Phi",
    ],
    Interests: ["Coding", "THON", "Dance"],
  };
  return (
    <main id="login-profile-box" onClick = {onclick}>
      <div className="basicinfo-wrapper">
        <img src={profilePicture} width= "70px"/>
        <div className="namebuttons-wrapper">
          <div className="name-content">{profileInfo.name}</div>
          <div className="basicinfobuttons-wrapper">
            <IconButton
              className="calendar-button"
              Icon={CalendarMonthIcon}
              iconHeight={40}
              iconFill={"black"}
            />
            <IconButton
              className="settings-button"
              Icon={SettingsIcon}
              iconHeight={40}
              iconFill={"black"}
            />
          </div>
        </div>
      </div>

      <div className="detailsinfo-wrapper">
        <div className="affiliation-wrapper">
          <div className="section-head-wrapper">
            <div className="section-name-content">Profile</div>
            <HyperLink>Edit</HyperLink>
          </div>
          <ul className="section-list-content">
            <div className="semester-standing-content">
              {profileInfo.SemStanding}
            </div>
            <li>{profileInfo.Profile[0]}</li>
            <li>{profileInfo.Profile[1]}</li>
          </ul>
        </div>
        <div className="position-wrapper">
          <div className="section-head-wrapper">
            <div className="section-name-content">Involvement</div>
            <HyperLink>Edit</HyperLink>
          </div>
          <ul className="section-list-content">
            <li>{profileInfo.Involvement[0]}</li>
            <li>{profileInfo.Involvement[1]}</li>
          </ul>
        </div>
        <div className="interests-wrapper">
          <div className="section-head-wrapper">
            <div className="section-name-content">Interests</div>
            <HyperLink>Edit</HyperLink>
          </div>
          <div className="intereststags-wrapper">
            {profileInfo.Interests.map((interest) => (
              <LabelButton key={interest}>{interest}</LabelButton>
            ))}
          </div>
        </div>
      </div>
    </main>
  );
}

export default LogInProfileBox;
