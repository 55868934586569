/*
This JavaScript file contains literals used in footer section of the page
Changes made in this file will affect the footer.
*/

const footerSection = [
  {
    id: 1,
    name: "PRODUCT",
    subcategory: [
      {
        sid: 1,
        name: "Competitions",
        link: "/competition",
      },
      {
        sid: 2,
        name: "Scholarships",
        link: "/scholarship",
      },
      {
        sid: 3,
        name: "Networking Events",
        link: "/network",
      },
    ],
  },
  {
    id: 2,
    name: "COMPANY",
    subcategory: [
      {
        sid: 1,
        name: "About",
        link: "/aboutus",
      },
      {
        sid: 2,
        name: "Careers",
        link: "https://beyondclass-official.notion.site/d44966110f7b4809af07f22791f20a87?v=ac8b624e68ae47b590466db3ed451625",
      },
    ],
  },
  {
    id: 3,
    name: "HELP",
    subcategory: [
      {
        sid: 1,
        name: "Contact us",
        link: "mailto:beyondclass@business.com",
      },
      {
        sid: 2,
        name: "Join us",
        link: "https://forms.gle/fxtWqNdcudfrfBxb6",
      },
    ],
  },
];

export { footerSection };
