import React from "react";
import { useState } from "react";
// import scss stylesheet
import "./Calendar.scss";
// import component dependencies
import Footer from "common/Footer/Footer";
import Header from "common/Header/Header.jsx";
import SearchBar from "common/SearchBar/SearchBar";
import ProfileBox from "common/ProfileBox/LogInProfileBox/LogInProfileBox";
import IconButton from "common/Button/IconButton/IconButton";
import IconWithLabelButton from "common/Button/IconWithLabelButton/IconWithLabelButton";
import WeeklyCalendar from "pages/Calendar/components/WeeklyCalendar/WeeklyCalendar";
import MonthlyCalendar from "pages/Calendar/components/MonthlyCalendar/MonthlyCalendar";
// import assets
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";

const Calendar = () => {
  const [viewMonth, setViewMonth] = useState(true);

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return (
    <>
      <Header />
      <main id="calendar">
        <section className="searchengine-wrapper">
          <div className="searchengine-objects-wrapper">
            <SearchBar className="searchbar-content" />
          </div>
        </section>

        <section className="calendarpage-large-wrapper">
          <div className="calendarpage-items-wrapper">
            <article className="article-wrapper">
              <section className="search-filter-wrapper">
                <p>This is the place for search filters</p>
              </section>
              <article className="article-wrapper">
                <div className="range-change-wrapper"></div>
                <h2 className="month-year-content">September, 2022</h2>
                <div className="view-switch-wrapper"></div>
                <section className="calendar-menu-wrapper"></section>
              </article>
            </article>
            <aside className="profilebox-wrapper">
              <ProfileBox />
            </aside>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Calendar;
