import React from "react";
import TopBar from "common/Header/TopBar/TopBar.jsx";
import CategoryBox from "common/Header/CategoryBox/CategoryBox.jsx";
import "./Header.scss";

function Header({ isLoggedIn }) {
  return (
    <div className="header-item-wrapper">
      <TopBar isLoggedIn={isLoggedIn} />
      <CategoryBox />
    </div>
  );
}

export default Header;
