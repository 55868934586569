import React, { useEffect } from "react";
import { useState } from "react";
import "setup/App.scss";
import AppRouter from "setup/routes/router";
import { authService } from "setup/firebase/fbase";
// use static footer
import Footer from "common/Footer/Footer";
import ScrollButton from "common/ScrollButton/ScrollButton";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  const [init, setInit] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    authService.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
      setInit(true);
    });
  }, []);
  return (
    <div className="App">
      {init ? (
        <div className="app">
          <AppRouter isLoggedIn={isLoggedIn} />
        </div>
      ) : (
        <div className="initialize-tab">Initializing...</div>
      )}
      
      <Router>
        <Footer />
        <ScrollButton/>
      </Router>
    </div>
  );
}

export default App;
