import React from 'react';


function SchoTitle({titleData}) {
    return (
        <div className = "title-rectangle-wrapper">
          <div className = "title-content-wrapper">
            <div className = "title-text-content">{titleData.name}</div>
            <div className = "title-requirement-wrapper">
              <div className = "requirement-wrapper">
                <div className = "requirement-title-content">Deadline: </div> 
                <span className = "requirement-input-content">{titleData.deadline}</span>
              </div>
              <div className = "requirement-wrapper">
                <div className = "requirement-title-content">Awards: </div> 
                <div className = "requirement-input-content">{titleData.award[1] + " $" +titleData.award[0]}</div>
              </div>
              <div className = "requirement-wrapper">
                <div className = "requirement-title-content">College: </div>
                <div className = "requirement-input-content">{titleData.eligibility[0]}</div>
              </div>
              <div className = "requirement-wrapper">
                <div className = "requirement-title-content">GPA: </div>
                <div className = "requirement-input-content">{titleData.eligibility[2]}</div>
              </div>
              <div className = "requirement-wrapper">
                <div className = "requirement-title-content">Major: </div>
                <div className = "requirement-input-content">{titleData.eligibility[1]}</div>
              </div>
              <div className = "requirement-wrapper">
                <div className = "requirement-title-content">Submission: </div>
                <div className = "requirement-input-content">{titleData.apply}</div>
              </div>
            </div>
          </div>
        </div>
    )
}

export default SchoTitle;