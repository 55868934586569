import React from "react";
import "./AboutUs.scss";
import beyondclasslogo from "assets/brand/beyondClassLogo.svg";
import beyondclassvideo from "assets/video/beyondClassVideo.mp4"
import soc from "assets/image/soc-poster.png";
import mainPage from "assets/image/mainPage.png"
import profileBox from "assets/image/profileBox.png"
import calendarPage from "assets/image/calendarPage.png"

function AboutUs() {
  const onClick = () => {
    console.log("submit");
    window.open("https://forms.gle/TtmKH4bnSZLFSXp78");
  };
  
  return (
    <>
      <main id="AboutUs">
        <div className="about-page-wrapper">
          <div className="about-empower-wrapper">
            <h1>Empower Your Success</h1>
            <img src={beyondclasslogo} height="50rem" />
            <button onClick={onClick} className="waitlist-button">
              Sign up to be on our waitlist
            </button>
          </div>
          <div className="about-why-wrapper">
            <div className="about-why-content-wrapper">
              <h1>Why BeyondClass?</h1>
              <h2 className="why-second-title-content">
                      Re-define your Opportunity with US,
              </h2>
              <div className="why-video">
                <video width="800" height="auto" controls="controls"> 
                  <source src={beyondclassvideo} type="video/mp4"></source>
                  sorry, your browser doesn't support the video
                </video>
              </div> 
              <div className="why-mission-square-wrapper">
                BeyondClass, a new disruptor solving educational inequality by closing your information
                gaps and defining the new paradigm of opportunities.
              </div>
            </div>
          </div>

          <div className="about-envision-wrapper">
            <div className="about-envision-content-wrapper">
              <div>
                "We are on a mission to build a centralized hub that provides
                students with easier and fairer access to all opportunities."
              </div>
            </div>
          </div>

          <div className="about-exclusive-wrapper">
            <div className="about-exclusive-content-wrapper">
              <div className="about-exclusive-poster">
                <img src={soc} />
                <div>
                  Check our exclusive competition with Professor Sam Richards,
                  Sponsored by SOC 119
                </div>
              </div>
              <div className="about-exclusive-text-wrapper">
                <h1>Exclusive Academic Challenges</h1>
                <span>
                    We want students to shape themselves <br/> in and out of physical
                    communities <br/> and to connect with others beyond class!
                </span>
                <div className="about-exclusive-des-wrapper">
                    <h3>
                      Great rewards lined up for you, students!
                    </h3>
                    <ul>
                      <li>Internships</li>
                      <li>Monetary Prizes</li>
                      <li>Awards</li>
                      <li>Exclusive Networking Events</li>
                      <li>You name it? We'll prepare!</li>
                    </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="about-upcoming-wrapper">
            <div className="about-upcoming-content-wrapper">
              <h1>Our Upcoming Features</h1>
              <div className="upcoming-feature-square-wrapper">
                <div className= "feature-main-calendar-wrapper">
                  <div className="feature-main-content">
                    <img src={mainPage} width= "200"/>
                    <div className= "text-wrapper">
                      <h2>Various Extracurricular Activities</h2>
                      <h3>Want to participate case competition, but an engaging student? <br/>No problem!</h3>
                    </div>
                  </div>
                  <div className="feature-calendar-content">
                    <img src={calendarPage} width= "350"/>
                    <div className= "text-wrapper">
                      <h2>Powerful Event Calendar</h2>
                      <h3>Star events that fit you the most. Connect with your favorite calendar service and stay tuned!</h3>
                    </div>
                  </div>
                </div>
                <div className="feature-profile-content">
                  <img src={profileBox} width="auto" height="500"/>
                  <div>
                    <h2>Dynamic Profile</h2>
                    <h3>Share your professional profile with others and look for friends on campus who share the interest!</h3>
                  </div> 
                </div>  
              </div>
            </div>
          </div>
          <div className="about-waitlist-wrapper">
            <h1>Expect our launch in August!</h1>
                <div>
                  Sign up to be on our waitlist and enjoy your benefits before anyone else!
                </div>
                <button onClick={onClick} className="waitlist-button">
                Sign up to be on our waitlist
                </button>
            </div>
        </div>
      </main>
    </>
  );
}

export default AboutUs;