import React from "react";
import "./Privacy.scss";

const Privacy = () => {
  return (
    <>
      <title>Privacy Policy</title>
      <article id="b29e66b1-86e0-4726-89d5-5e658f597bdd" className="page sans">
        <div className="page-body">
          <h3 id="f238a5b2-6cad-47a6-a7fe-d4f4a23fd15c" className="">
            PRIVACY NOTICE
          </h3>
          <p id="b3408dc2-0e43-43ab-ba48-5f556c2a1c8e" className="">
            <strong>Last updated February 26, 2023</strong>
          </p>
          <p id="e0ccfab1-b3b5-4ce1-995f-084e2986718d" className="">
            This privacy notice for BeyondClass ("<strong>Company</strong>," "
            <strong>we</strong>," "<strong>us</strong>," or "
            <strong>our</strong>"), describes how and why we might collect,
            store, use, and/or share ("
            <strong>process</strong>") your information when you use our
            services ("
            <strong>Services</strong>"), such as when you:
          </p>
          <ul
            id="15692d8e-4d22-43b0-88cc-c385450fff9d"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              Visit our website at{" "}
              <a href="https://beyondclass.net/">https://beyondclass.net/</a>,
              or any website of ours that links to this privacy notice
            </li>
          </ul>
          <ul
            id="8413f8dc-d9cc-4a36-8689-f53218edcb3b"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              Engage with us in other related ways, including any sales,
              marketing, or events
            </li>
          </ul>
          <p id="aeaeda58-0ce6-429c-a9e0-6ccff91efc9f" className="">
            <strong>Questions or concerns?&nbsp;</strong>Reading this privacy
            notice will help you understand your privacy rights and choices. If
            you do not agree with our policies and practices, please do not use
            our Services. If you still have any questions or concerns, please
            contact us at beyondclass.business@gmail.com.
          </p>
          <p id="1f126e94-29cb-412a-9141-771840fcc1e3" className=""></p>
          <h3 id="247a470d-185c-4a27-90cd-4a2171551311" className="">
            SUMMARY OF KEY POINTS
          </h3>
          <p id="1b36d8c3-1802-4699-9854-8805fa808f1b" className="">
            <em>
              <strong>
                This summary provides key points from our privacy notice, but
                you can find out more details about any of these topics by
                clicking the link following each key point or by using our table
                of contents below to find the section you are looking for. You
                can also click&nbsp;
              </strong>
            </em>
            <a href="about:blank#toc">
              <em>
                <strong>here</strong>
              </em>
            </a>
            <em>
              <strong>&nbsp;to go directly to our table of contents.</strong>
            </em>
          </p>
          <p id="87d47b38-1146-4834-899c-ffd843ab2747" className="">
            <strong>What personal information do we process?</strong> When you
            visit, use, or navigate our Services, we may process personal
            information depending on how you interact with BeyondClass and the
            Services, the choices you make, and the products and features you
            use. Click&nbsp;<a href="about:blank#personalinfo">here</a>&nbsp;to
            learn more.
          </p>
          <p id="811bbe75-bafe-4b5d-ad2e-aa9da0df376e" className="">
            <strong>Do we process any sensitive personal information?</strong>{" "}
            We may process sensitive personal information when necessary with
            your consent or as otherwise permitted by applicable law.
            Click&nbsp;
            <a href="about:blank#sensitiveinfo">here</a>&nbsp;to learn more.
          </p>
          <p id="be13980b-1d34-4e6e-848b-052d8ef48e89" className="">
            <strong>Do we receive any information from third parties?</strong>{" "}
            We may receive information from public databases, marketing
            partners, social media platforms, and other outside sources.
            Click&nbsp;
            <a href="about:blank#othersources">here</a>&nbsp;to learn more.
          </p>
          <p id="ad1a8dfc-0473-427b-9873-b455d4b5a672" className="">
            <strong>How do we process your information?</strong> We process your
            information to provide, improve, and administer our Services,
            communicate with you, for security and fraud prevention, and to
            comply with law. We may also process your information for other
            purposes with your consent. We process your information only when we
            have a valid legal reason to do so. Click&nbsp;
            <a href="about:blank#infouse">here</a>
            &nbsp;to learn more.
          </p>
          <p id="80c7d138-d659-489b-87dd-037df9bafa9e" className="">
            <strong>
              In what situations and with which parties do we share personal
              information?
            </strong>{" "}
            We may share information in specific situations and with specific
            third parties. Click&nbsp;<a href="about:blank#whoshare">here</a>
            &nbsp;to learn more.
          </p>
          <p id="8143446d-8540-4e8f-a45f-f6fffab3b3c8" className="">
            <strong>What are your rights?</strong> Depending on where you are
            located geographically, the applicable privacy law may mean you have
            certain rights regarding your personal information. Click&nbsp;
            <a href="about:blank#privacyrights">here</a>&nbsp;to learn more.
          </p>
          <p id="61341bad-708f-4f4a-9ed9-37cad377c003" className="">
            <strong>How do you exercise your rights?</strong> The easiest way to
            exercise your rights is by filling out our data subject request form
            available{" "}
            <a href="https://app.termly.io/notify/ad2ea2cf-a6f9-4cc1-8670-5a6530b6e4ce">
              here
            </a>
            , or by contacting us. We will consider and act upon any request in
            accordance with applicable data protection laws.
          </p>
          <p id="74b03a29-23cf-433a-8c59-6bc438ac05a4" className="">
            Want to learn more about what BeyondClass does with any information
            we collect? Click&nbsp;<a href="about:blank#toc">here</a>&nbsp;to
            review the notice in full.
          </p>
          <p id="e5e78c48-5185-459e-82b2-64b06102d8a7" className=""></p>
          <h3 id="01c3d10b-8bee-48af-ba3f-f17a814469a2" className="">
            TABLE OF CONTENTS
          </h3>
          <p id="48a2ae61-cb4a-4cb4-8353-89658d55498d" className="">
            <a href="about:blank#infocollect">
              1. WHAT INFORMATION DO WE COLLECT?
            </a>
          </p>
          <p id="a48729e6-4e08-4b16-9987-72ce85e67c47" className="">
            <a href="about:blank#infouse">
              2. HOW DO WE PROCESS YOUR INFORMATION?
            </a>
          </p>
          <p id="83babcd5-4f49-426b-93eb-cb4728a7fbf3" className="">
            <a href="about:blank#legalbases">
              3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
              INFORMATION?
            </a>
          </p>
          <p id="c690d75c-b266-45a4-b5b9-3d1800e5d375" className="">
            <a href="about:blank#whoshare">
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </a>
          </p>
          <p id="cb1e03b0-719d-48f9-baf6-b1b946e1e3aa" className="">
            <a href="about:blank#cookies">
              5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </a>
          </p>
          <p id="7db6abf3-a7b8-4a64-9368-01f6a8aa9348" className="">
            <a href="about:blank#sociallogins">
              6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
            </a>
          </p>
          <p id="7e6ef613-50c6-486e-8436-2967051f4a5e" className="">
            <a href="about:blank#inforetain">
              7. HOW LONG DO WE KEEP YOUR INFORMATION?
            </a>
          </p>
          <p id="f516dcf0-2f81-4e4f-a90f-0110fdca8d5d" className="">
            <a href="about:blank#privacyrights">
              8. WHAT ARE YOUR PRIVACY RIGHTS?
            </a>
          </p>
          <p id="44404511-9717-4f5e-ad9c-0f8e058c72f7" className="">
            <a href="about:blank#DNT">9. CONTROLS FOR DO-NOT-TRACK FEATURES</a>
          </p>
          <p id="65412e0a-8700-4289-a249-5ffd1a0b31a2" className="">
            <a href="about:blank#caresidents">
              10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </a>
          </p>
          <p id="87477918-74fd-4a75-b917-312d0dbb4f3d" className="">
            <a href="about:blank#policyupdates">
              11. DO WE MAKE UPDATES TO THIS NOTICE?
            </a>
          </p>
          <p id="0e536480-21c1-4bd8-9af5-7f001ba02107" className="">
            <a href="about:blank#contact">
              12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </a>
          </p>
          <p id="0f29bd3a-57f2-4fb0-a089-669419d00168" className="">
            <a href="about:blank#request">
              13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </a>
          </p>
          <p id="94bd4b5a-0f38-4878-b86c-69384c8115df" className=""></p>
          <h3 id="3680f631-a127-491a-ae53-6538c7f56332" className="">
            1.<strong> WHAT INFORMATION DO WE COLLECT?</strong>
          </h3>
          <p id="53e0e416-467d-4a75-961a-183852da9473" className="">
            <strong>Personal information you disclose to us</strong>
          </p>
          <p id="cd5e36f4-a67c-4ad9-a80b-0e0a6bb7ef2d" className="">
            <em>
              <strong>In Short:</strong>
            </em>
            <em>
              <strong>&nbsp;</strong>
            </em>
            <em>We collect personal information that you provide to us.</em>
          </p>
          <p id="aadb216f-d034-488e-bb3e-7cbb74465188" className="">
            We collect personal information that you voluntarily provide to us
            when you register on the Services,&nbsp;express an interest in
            obtaining information about us or our products and Services, when
            you participate in activities on the Services, or otherwise when you
            contact us.
          </p>
          <p id="8051a815-8292-4be3-a514-a572c3c664d3" className="">
            <strong>Personal Information Provided by You.</strong> The personal
            information that we collect depends on the context of your
            interactions with us and the Services, the choices you make, and the
            products and features you use. The personal information we collect
            may include the following:
          </p>
          <ul
            id="8661eea3-1e30-46fe-828b-fac1eec36d1a"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>names</li>
          </ul>
          <ul
            id="86d0f5fd-1a0e-4d86-a35c-27094c924aa6"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>email addresses</li>
          </ul>
          <ul
            id="bc1539cb-e87b-4bcf-a857-13ad3fb64b82"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>job titles</li>
          </ul>
          <ul
            id="3c790dcb-ffb0-41db-b463-0f8ed487bd85"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>usernames</li>
          </ul>
          <ul
            id="157c6dc3-67d6-4feb-8516-314bcdd78878"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>passwords</li>
          </ul>
          <ul
            id="c7ef98b1-662b-4a53-a60c-91268c5ed8f3"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>phone numbers</li>
          </ul>
          <ul
            id="967be500-06d6-4ad2-bec0-eed42ea4708e"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>contact preferences</li>
          </ul>
          <ul
            id="a286637e-28b3-462d-99a3-f58c7de9053e"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              contact or authentication data
            </li>
          </ul>
          <ul
            id="adbd6afc-5d89-4dc9-b6a4-fab9877ce3c5"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>billing addresses</li>
          </ul>
          <ul
            id="3263904a-5c2e-428b-ab8d-f9ecadba49a2"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>debit/credit card numbers</li>
          </ul>
          <ul
            id="f7ab5a7c-149b-4a08-883c-6a897f49c1f6"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>date of birth</li>
          </ul>
          <ul
            id="5fb29521-7b04-4502-aede-e8924a69fc12"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>gender</li>
          </ul>
          <ul
            id="837f118a-e44a-40b4-8596-1b65ea438434"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>educational background</li>
          </ul>
          <p id="23308895-3b91-43d0-9639-329ddfaaff77" className="">
            <strong>Sensitive Information.</strong> When necessary, with your
            consent or as otherwise permitted by applicable law, we process the
            following categories of sensitive information:
          </p>
          <ul
            id="2a924a95-a0ad-4727-8dae-e59f1021549c"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              data about a person's sex life or sexual orientation
            </li>
          </ul>
          <ul
            id="864af36a-9528-4b19-8047-70ca340d0644"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>student data</li>
          </ul>
          <p id="15f31334-123f-44d5-bdb8-2ef93f5c3960" className="">
            <strong>Social Media Login Data.&nbsp;</strong>We may provide you
            with the option to register with us using your existing social media
            account details, like your Facebook, Twitter, or other social media
            account. If you choose to register in this way, we will collect the
            information described in the section called "
            <a href="about:blank#sociallogins">
              HOW DO WE HANDLE YOUR SOCIAL LOGINS?
            </a>
            " below.
          </p>
          <p id="929d7279-7583-4e3c-947f-40d7ae2fb360" className="">
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>
          <p id="08399f63-71cf-441b-9c3c-37966698afc5" className="">
            <strong>Information automatically collected</strong>
          </p>
          <p id="00c1cf30-71a8-4fc0-98a8-f0b2a9c680ca" className="">
            <em>
              <strong>In Short:</strong>
            </em>
            <em>
              <strong>&nbsp;</strong>
            </em>
            <em>
              Some information — such as your Internet Protocol (IP) address
              and/or browser and device characteristics — is collected
              automatically when you visit our Services.
            </em>
          </p>
          <p id="58e00d03-4b93-47cd-942c-b9eb11f24ead" className="">
            We automatically collect certain information when you visit, use, or
            navigate the Services. This information does not reveal your
            specific identity (like your name or contact information) but may
            include device and usage information, such as your IP address,
            browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location,
            information about how and when you use our Services, and other
            technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our
            internal analytics and reporting purposes.
          </p>
          <p id="02144a41-0a46-4b58-a14f-a29558a299a1" className="">
            Like many businesses, we also collect information through cookies
            and similar technologies.
          </p>
          <p id="cc9948ff-4718-40f5-8314-a4e95800581d" className="">
            The information we collect includes:
          </p>
          <ul
            id="b9e9b376-7a56-489f-be11-351f28b50a65"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <em>Log and Usage Data.</em> Log and usage data is
              service-related, diagnostic, usage, and performance information
              our servers automatically collect when you access or use our
              Services and which we record in log files. Depending on how you
              interact with us, this log data may include your IP address,
              device information, browser type, and settings and information
              about your activity in the Services&nbsp;(such as the date/time
              stamps associated with your usage, pages and files viewed,
              searches, and other actions you take such as which features you
              use), device event information (such as system activity, error
              reports (sometimes called "crash dumps"), and hardware settings).
            </li>
          </ul>
          <ul
            id="c8057325-103a-445d-8c20-b991cdc79b66"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <em>Device Data.</em> We collect device data such as information
              about your computer, phone, tablet, or other device you use to
              access the Services. Depending on the device used, this device
              data may include information such as your IP address (or proxy
              server), device and application identification numbers, location,
              browser type, hardware model, Internet service provider and/or
              mobile carrier, operating system, and system configuration
              information.
            </li>
          </ul>
          <ul
            id="e594e61a-4acc-4818-ba63-95a87f586ca0"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <em>Location Data.</em> We collect location data such as
              information about your device's location, which can be either
              precise or imprecise. How much information we collect depends on
              the type and settings of the device you use to access the
              Services. For example, we may use GPS and other technologies to
              collect geolocation data that tells us your current location
              (based on your IP address). You can opt out of allowing us to
              collect this information either by refusing access to the
              information or by disabling your Location setting on your device.
              However, if you choose to opt out, you may not be able to use
              certain aspects of the Services.
            </li>
          </ul>
          <p id="2f9ee8b3-c2ad-40de-bf40-c672dcc27a13" className="">
            <strong>Information collected from other sources</strong>
          </p>
          <p id="9a38809b-43ed-407b-9f4c-593975aeb392" className="">
            <em>
              <strong>In Short:&nbsp;</strong>
            </em>
            <em>
              We may collect limited data from public databases, marketing
              partners,{" "}
            </em>
            <em>social media platforms, </em>
            <em>and other outside sources.</em>
          </p>
          <p id="740aca19-f740-438d-9efa-8fde52bedfc8" className="">
            In order to enhance our ability to provide relevant marketing,
            offers, and services to you and update our records, we may obtain
            information about you from other sources, such as public databases,
            joint marketing partners, affiliate programs, data providers, social
            media platforms,&nbsp;and from other third parties. This information
            includes mailing addresses, job titles, email addresses, phone
            numbers, intent data (or user behavior data), Internet Protocol (IP)
            addresses, social media profiles, social media URLs, and custom
            profiles, for purposes of targeted advertising and event
            promotion.&nbsp;If you interact with us on a social media platform
            using your social media account (e.g., Facebook or Twitter), we
            receive personal information about you such as your name, email
            address, and gender. Any personal information that we collect from
            your social media account depends on your social media account's
            privacy settings.
          </p>
          <p id="c4ba208f-7b02-40d5-93ec-ea56552171f9" className=""></p>
          <h3 id="43b3aa42-ab59-4bc9-9d7a-f738bc5adeeb" className="">
            2. <strong>HOW DO WE PROCESS YOUR INFORMATION?</strong>
          </h3>
          <p id="fde1ed49-589b-4edb-be1a-106e4d462b4b" className="">
            <em>
              <strong>In Short:&nbsp;</strong>
            </em>
            <em>
              We process your information to provide, improve, and administer
              our Services, communicate with you, for security and fraud
              prevention, and to comply with law. We may also process your
              information for other purposes with your consent.
            </em>
          </p>
          <p id="382d1532-cd7c-42c4-aa85-9ff5929368db" className="">
            <strong>
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </strong>
          </p>
          <ul
            id="378f20f1-a917-43d6-b934-a79cad7896e9"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>
                To facilitate account creation and authentication and otherwise
                manage user accounts.&nbsp;
              </strong>
              We may process your information so you can create and log in to
              your account, as well as keep your account in working order.
            </li>
          </ul>
          <ul
            id="8b4d92fa-9a52-4df8-8b27-1847e5782e2d"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>
                To deliver and facilitate delivery of services to the
                user.&nbsp;
              </strong>
              We may process your information to provide you with the requested
              service.
            </li>
          </ul>
          <ul
            id="04822ca5-7688-41e0-b835-ac26883bd242"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>
                To respond to user inquiries/offer support to users.&nbsp;
              </strong>
              We may process your information to respond to your inquiries and
              solve any potential issues you might have with the requested
              service.
            </li>
          </ul>
          <ul
            id="2b46ff79-9a34-4d46-933d-ca4b9b23a238"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>To enable user-to-user communications.&nbsp;</strong>We
              may process your information if you choose to use any of our
              offerings that allow for communication with another user.
            </li>
          </ul>
          <ul
            id="3e986dac-4f51-45f6-8be2-395416a1a9c4"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>
                To send you marketing and promotional communications.&nbsp;
              </strong>
              We may process the personal information you send to us for our
              marketing purposes, if this is in accordance with your marketing
              preferences. You can opt out of our marketing emails at any time.
              For more information, see "
              <a href="about:blank#privacyrights">
                WHAT ARE YOUR PRIVACY RIGHTS?
              </a>
              " below).
            </li>
          </ul>
          <ul
            id="cad0ab1e-b9f6-48b2-9459-c9730d447dcb"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>To deliver targeted advertising to you.</strong> We may
              process your information to develop and display personalized
              content and advertising tailored to your interests, location, and
              more.
            </li>
          </ul>
          <ul
            id="06d95107-a279-47da-b037-efa24e547983"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>To protect our Services.</strong> We may process your
              information as part of our efforts to keep our Services safe and
              secure, including fraud monitoring and prevention.
            </li>
          </ul>
          <ul
            id="81acd51c-dc05-49da-89f1-86e2f7878257"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>To identify usage trends.</strong> We may process
              information about how you use our Services to better understand
              how they are being used so we can improve them.
            </li>
          </ul>
          <ul
            id="1821fcce-cd44-4e96-af41-68cf4c9aa217"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>
                To determine the effectiveness of our marketing and promotional
                campaigns.
              </strong>{" "}
              We may process your information to better understand how to
              provide marketing and promotional campaigns that are most relevant
              to you.
            </li>
          </ul>
          <ul
            id="b568462f-3c96-4351-9322-d75db7e9363d"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>
                To save or protect an individual's vital interest.
              </strong>{" "}
              We may process your information when necessary to save or protect
              an individual’s vital interest, such as to prevent harm.
            </li>
          </ul>
          <p id="86e1d07e-e5a3-4aa7-ace0-7661600137c0" className=""></p>
          <h3 id="064bb4b7-b80b-4a8a-8d97-ac42c4d09e5a" className="">
            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
          </h3>
          <p id="0c331d05-0b45-4abc-820a-5dbf1f9b3de6" className="">
            <em>
              <strong>In Short:&nbsp;</strong>
            </em>
            <em>
              We only process your personal information when we believe it is
              necessary and we have a valid legal reason (i.e., legal basis) to
              do so under applicable law, like with your consent, to comply with
              laws, to provide you with services to enter into or fulfill our
              contractual obligations, to protect your rights, or to fulfill our
              legitimate business interests.
            </em>
          </p>
          <p id="f0cf6232-c822-4722-b042-d99faeef4d9b" className="">
            <em>
              <strong>
                If you are located in the EU or UK, this section applies to you.
              </strong>
            </em>
          </p>
          <p id="16e8bf85-fd40-43eb-b50b-8c54e6fcae36" className="">
            The General Data Protection Regulation (GDPR) and UK GDPR require us
            to explain the valid legal bases we rely on in order to process your
            personal information. As such, we may rely on the following legal
            bases to process your personal information:
          </p>
          <ul
            id="fb64edde-faf6-471f-b318-0f3f2b671320"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>Consent.&nbsp;</strong>We may process your information if
              you have given us permission (i.e., consent) to use your personal
              information for a specific purpose. You can withdraw your consent
              at any time. Click&nbsp;
              <a href="about:blank#withdrawconsent">here</a>
              &nbsp;to learn more.
            </li>
          </ul>
          <ul
            id="b4aff512-b972-4316-b4de-4c9cf6b3425e"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>Performance of a Contract.</strong> We may process your
              personal information when we believe it is necessary to fulfill
              our contractual obligations to you, including providing our
              Services or at your request prior to entering into a contract with
              you.
            </li>
          </ul>
          <ul
            id="267c32f4-12c7-4479-9c63-5fa1f6b61150"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>Legitimate Interests.</strong> We may process your
              information when we believe it is reasonably necessary to achieve
              our legitimate business interests and those interests do not
              outweigh your interests and fundamental rights and freedoms. For
              example, we may process your personal information for some of the
              purposes described in order to:
            </li>
          </ul>
          <ul
            id="20bc51af-bdfb-4203-9ad1-afe5ab11089a"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              Send users information about special offers and discounts on our
              products and services
            </li>
          </ul>
          <ul
            id="08cb4a69-686b-4d21-84c0-3bafa41e7314"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              Develop and display personalized and relevant advertising content
              for our users
            </li>
          </ul>
          <ul
            id="db2f90c4-9f6f-4ff8-a497-640fbe429925"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              Analyze how our Services are used so we can improve them to engage
              and retain users
            </li>
          </ul>
          <ul
            id="bbe72288-bdf0-44f2-9566-3358929f1d18"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              Support our marketing activities
            </li>
          </ul>
          <ul
            id="e622da0a-dc7b-4130-995a-4e589b0aa027"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              Diagnose problems and/or prevent fraudulent activities
            </li>
          </ul>
          <ul
            id="b575ba60-2b3c-4449-b4a9-9addf19099ae"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>Legal Obligations.</strong> We may process your
              information where we believe it is necessary for compliance with
              our legal obligations, such as to cooperate with a law enforcement
              body or regulatory agency, exercise or defend our legal rights, or
              disclose your information as evidence in litigation in which we
              are involved.
            </li>
          </ul>
          <ul
            id="8bb8607a-2c9e-40a0-81b8-982d2e9b5919"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>Vital Interests.</strong> We may process your information
              where we believe it is necessary to protect your vital interests
              or the vital interests of a third party, such as situations
              involving potential threats to the safety of any person.
            </li>
          </ul>
          <p id="528a2879-495e-4fc3-97f3-2bc1b8734906" className="">
            <em>
              <strong>
                If you are located in Canada, this section applies to you.
              </strong>
            </em>
          </p>
          <p id="122d70cc-bbf5-40b1-b81e-a601b195a6ee" className="">
            We may process your information if you have given us specific
            permission (i.e., express consent) to use your personal information
            for a specific purpose, or in situations where your permission can
            be inferred (i.e., implied consent). You can withdraw your consent
            at any time. Click&nbsp;
            <a href="about:blank#withdrawconsent">here</a>&nbsp;to learn more.
          </p>
          <p id="5189fb8b-5cc8-4606-a510-5cf3ce3da1f5" className="">
            In some exceptional cases, we may be legally permitted under
            applicable law to process your information without your consent,
            including, for example:
          </p>
          <ul
            id="b820a004-916b-45d3-8bd3-6eccfac09582"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              If collection is clearly in the interests of an individual and
              consent cannot be obtained in a timely way
            </li>
          </ul>
          <ul
            id="2d9a68e9-943b-4b9d-bdd5-13b5c6377c8d"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              For investigations and fraud detection and prevention
            </li>
          </ul>
          <ul
            id="18d944ec-21b7-4ef5-bf7c-ce9088e53238"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              For business transactions provided certain conditions are met
            </li>
          </ul>
          <ul
            id="8641d765-efc6-4b60-b021-9a7e3cb5f0ec"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              If it is contained in a witness statement and the collection is
              necessary to assess, process, or settle an insurance claim
            </li>
          </ul>
          <ul
            id="5c366d63-1193-4c6e-9250-24c88bc7cd13"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              For identifying injured, ill, or deceased persons and
              communicating with next of kin
            </li>
          </ul>
          <ul
            id="675ad6a5-2404-486f-95bd-af7cf6ffc788"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              If we have reasonable grounds to believe an individual has been,
              is, or may be victim of financial abuse
            </li>
          </ul>
          <ul
            id="5412be85-11c2-4e00-9fac-fd9f6f813c0f"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              If it is reasonable to expect collection and use with consent
              would compromise the availability or the accuracy of the
              information and the collection is reasonable for purposes related
              to investigating a breach of an agreement or a contravention of
              the laws of Canada or a province
            </li>
          </ul>
          <ul
            id="7daa3b78-b64d-4460-a99c-aba06a1b7b07"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              If disclosure is required to comply with a subpoena, warrant,
              court order, or rules of the court relating to the production of
              records
            </li>
          </ul>
          <ul
            id="3d41fed7-67c6-410f-aad7-07b478b61c0c"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              If it was produced by an individual in the course of their
              employment, business, or profession and the collection is
              consistent with the purposes for which the information was
              produced
            </li>
          </ul>
          <ul
            id="3b30f949-ca04-43e0-8288-2ea27475bbed"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              If the collection is solely for journalistic, artistic, or
              literary purposes
            </li>
          </ul>
          <ul
            id="2c1882e1-ae68-453f-9519-7e905837f315"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              If the information is publicly available and is specified by the
              regulations
            </li>
          </ul>
          <p id="55c330f5-837e-482a-8d0d-6ef104da5adc" className=""></p>
          <h3 id="dd36846e-b418-4be5-98f4-68ce9c0b23c1" className="">
            4.{" "}
            <strong>
              WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </strong>
          </h3>
          <p id="47886545-777d-4ae4-8219-aefa3d23ae7e" className="">
            <em>
              <strong>In Short:</strong>
            </em>
            <em>
              &nbsp;We may share information in specific situations described in
              this section and/or with the following third parties.
            </em>
          </p>
          <p id="71c87506-b1d7-4c95-8580-49b779ca42d9" className="">
            We may need to share your personal information in the following
            situations:
          </p>
          <ul
            id="5bb075ca-9586-4915-9643-78075a527ef1"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>Business Transfers.</strong> We may share or transfer your
              information in connection with, or during negotiations of, any
              merger, sale of company assets, financing, or acquisition of all
              or a portion of our business to another company.
            </li>
          </ul>
          <ul
            id="a05d0483-50fa-4afa-acc1-7e6aac2900db"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>Affiliates.&nbsp;</strong>We may share your information
              with our affiliates, in which case we will require those
              affiliates to honor this privacy notice. Affiliates include our
              parent company and any subsidiaries, joint venture partners, or
              other companies that we control or that are under common control
              with us.
            </li>
          </ul>
          <ul
            id="2793a492-5bbe-49a6-9cf6-e78926b1d85f"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>Business Partners.</strong> We may share your information
              with our business partners to offer you certain products,
              services, or promotions.
            </li>
          </ul>
          <ul
            id="a1931421-1397-4b78-9db8-b84aa0e2dea7"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              <strong>Other Users.</strong> When you share personal information
              (for example, by posting comments, contributions, or other content
              to the Services) or otherwise interact with public areas of the
              Services, such personal information may be viewed by all users and
              may be publicly made available outside the Services in perpetuity.
              If you interact with other users of our Services and register for
              our Services through a social network (such as Facebook), your
              contacts on the social network will see your name, profile photo,
              and descriptions of your activity. Similarly, other users will be
              able to view descriptions of your activity, communicate with you
              within our Services, and view your profile.
            </li>
          </ul>
          <p id="77d645bc-8c50-470d-a3d0-7294c6246f34" className=""></p>
          <h3 id="c77e55f6-75c5-410a-9c1a-8cb8133cfb9f" className="">
            5.{" "}
            <strong>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</strong>
          </h3>
          <p id="eb0b773f-c889-4351-8cdc-732788922aa3" className="">
            <em>
              <strong>In Short:</strong>
            </em>
            <em>
              &nbsp;We may use cookies and other tracking technologies to
              collect and store your information.
            </em>
          </p>
          <p id="fcba3fc4-1aba-4c2d-854c-471c3b6053a3" className="">
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to access or store information. Specific
            information about how we use such technologies and how you can
            refuse certain cookies is set out in our Cookie Notice.
          </p>
          <p id="09090d7e-0a46-4f61-a33d-ed621089d357" className=""></p>
          <h3 id="665290c0-dd84-465e-8454-f4b3dfe5a44d" className="">
            6. <strong>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong>
          </h3>
          <p id="012bae94-168b-402f-b0b2-1571d355c87a" className="">
            <em>
              <strong>In Short:&nbsp;</strong>
            </em>
            <em>
              If you choose to register or log in to our Services using a social
              media account, we may have access to certain information about
              you.
            </em>
          </p>
          <p id="74500488-3c7f-4fe2-8971-5f5be78e616e" className="">
            Our Services offer you the ability to register and log in using your
            third-party social media account details (like your Facebook or
            Twitter logins). Where you choose to do this, we will receive
            certain profile information about you from your social media
            provider. The profile information we receive may vary depending on
            the social media provider concerned, but will often include your
            name, email address, friends list, and profile picture, as well as
            other information you choose to make public on such a social media
            platform.
          </p>
          <p id="26ea0254-4f36-4551-bdd7-201acbffbcbf" className="">
            We will use the information we receive only for the purposes that
            are described in this privacy notice or that are otherwise made
            clear to you on the relevant Services. Please note that we do not
            control, and are not responsible for, other uses of your personal
            information by your third-party social media provider. We recommend
            that you review their privacy notice to understand how they collect,
            use, and share your personal information, and how you can set your
            privacy preferences on their sites and apps.
          </p>
          <p id="a57968be-bc3f-4853-aa28-28a62c86bc4c" className=""></p>
          <h3 id="60a8c586-f0a6-4316-85fb-9af509472d20" className="">
            7. <strong>HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
          </h3>
          <p id="67f87d78-e572-49fb-b985-2068a6da99d7" className="">
            <em>
              <strong>In Short:&nbsp;</strong>
            </em>
            <em>
              We keep your information for as long as necessary to fulfill the
              purposes outlined in this privacy notice unless otherwise required
              by law.
            </em>
          </p>
          <p id="464ab8e8-c48e-4e18-b1a4-71617d4aaf17" className="">
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements). No purpose in this
            notice will require us keeping your personal information for longer
            than the period of time in which users have an account with us.
          </p>
          <p id="e3f5403a-f3ad-4ad6-809f-b74782e08f6a" className="">
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>
          <p id="14de247a-2e08-4785-8f84-f0b2d9a50d9a" className=""></p>
          <h3 id="61ba8f5d-843c-42b1-aa98-8037a3d3a9bd" className="">
            8. <strong>WHAT ARE YOUR PRIVACY RIGHTS?</strong>
          </h3>
          <p id="4f17bc77-9df5-4490-9403-4f358bd8b27b" className="">
            <strong>8. </strong>
            <em>
              <strong>In Short:</strong>
            </em>
            <em>
              &nbsp;In some regions, such as the European Economic Area (EEA),
              United Kingdom (UK), and Canada, you have rights that allow you
              greater access to and control over your personal
              information.&nbsp;You may review, change, or terminate your
              account at any time.
            </em>
          </p>
          <p id="d2e15402-e309-4d5e-a059-2137208ee41f" className="">
            In some regions (like the EEA, UK, and Canada), you have certain
            rights under applicable data protection laws. These may include the
            right (i) to request access and obtain a copy of your personal
            information, (ii) to request rectification or erasure; (iii) to
            restrict the processing of your personal information; and (iv) if
            applicable, to data portability. In certain circumstances, you may
            also have the right to object to the processing of your personal
            information. You can make such a request by contacting us by using
            the contact details provided in the section "
            <a href="about:blank#contact">
              HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </a>
            " below.
          </p>
          <p id="85ffb378-c258-4651-84b7-ef233fc71cd2" className="">
            We will consider and act upon any request in accordance with
            applicable data protection laws.
          </p>
          <p id="75c4ea93-7f82-4cdc-b684-e91d9ed25324" className="">
            If you are located in the EEA or UK and you believe we are
            unlawfully processing your personal information, you also have the
            right to complain to your local data protection supervisory
            authority. You can find their contact details here:{" "}
            <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
              https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
            </a>
            .
          </p>
          <p id="6b01a5d4-3fc8-43d1-8eb2-0dfe67c3b545" className="">
            If you are located in Switzerland, the contact details for the data
            protection authorities are available here:{" "}
            <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
              https://www.edoeb.admin.ch/edoeb/en/home.html
            </a>
            .
          </p>
          <p id="59d76a9e-8d0a-46f8-9092-e791cf0a33e9" className="">
            <strong>Withdrawing your consent:</strong> If we are relying on your
            consent to process your personal information, which may be express
            and/or implied consent depending on the applicable law, you have the
            right to withdraw your consent at any time. You can withdraw your
            consent at any time by contacting us by using the contact details
            provided in the section "
            <a href="about:blank#contact">
              HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </a>
            " below or updating your preferences.
          </p>
          <p id="8e95c961-a2e1-424f-9f80-8bb249b84da6" className="">
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor, when applicable law allows,
            will it affect the processing of your personal information conducted
            in reliance on lawful processing grounds other than consent.
          </p>
          <p id="f9e62260-b1c2-4d11-a0fa-2f3271104c19" className="">
            <strong>
              Opting out of marketing and promotional communications:&nbsp;
            </strong>
            You can unsubscribe from our marketing and promotional
            communications at any time by clicking on the unsubscribe link in
            the emails that we send, or by contacting us using the details
            provided in the section "
            <a href="about:blank#contact">
              HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </a>
            " below. You will then be removed from the marketing lists. However,
            we may still communicate with you — for example, to send you
            service-related messages that are necessary for the administration
            and use of your account, to respond to service requests, or for
            other non-marketing purposes.
          </p>
          <p id="62592478-c8f8-4c38-afc5-345f61b55d3a" className="">
            <strong>Account Information</strong>
          </p>
          <p id="3c5a5173-b4bd-47c1-a1db-1644fca5d071" className="">
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </p>
          <ul
            id="5449cffd-ae8d-4299-9da9-829be6d42335"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              Log in to your account settings and update your user account.
            </li>
          </ul>
          <ul
            id="a7f857d5-c2e7-435b-96d5-880518afd106"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              Contact us using the contact information provided.
            </li>
          </ul>
          <p id="e5e26787-a535-4c27-9e32-49de3ee985d0" className="">
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal
            requirements.
          </p>
          <p id="2829780f-6356-4562-949f-ee960a38f9c4" className="">
            <strong>Cookies and similar technologies:</strong> Most Web browsers
            are set to accept cookies by default. If you prefer, you can usually
            choose to set your browser to remove cookies and to reject cookies.
            If you choose to remove cookies or reject cookies, this could affect
            certain features or services of our Services. To opt out of
            interest-based advertising by advertisers on our Services visit{" "}
            <a href="http://www.aboutads.info/choices/">
              http://www.aboutads.info/choices/
            </a>
            .
          </p>
          <p id="374c489e-5c1a-476c-9365-849cf49a5542" className="">
            If you have questions or comments about your privacy rights, you may
            email us at{" "}
            <mark className="highlight-gray">
              <span style={{ borderBottom: "0.05em solid" }}>
                beyondclass.privacy@gmail.com
              </span>
            </mark>
            .
          </p>
          <p id="5b25c56c-c0ec-4552-b230-983ae8483368" className=""></p>
          <h3 id="cce06e99-d8c2-4e22-9438-3c93e4c8c352" className="">
            9. <strong>CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
          </h3>
          <p id="2cc60b12-73b9-4103-921e-531fd7e91a87" className="">
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </p>
          <p id="be6b305a-e364-42fb-92e5-060e5b02b5e4" className=""></p>
          <h3 id="aa444fa8-36cd-4445-a53f-f2fd126c66e7" className="">
            10.{" "}
            <strong>
              DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </strong>
          </h3>
          <p id="b4220d17-c39b-4b26-b7c8-b8f3bdfb41b7" className="">
            <em>
              <strong>In Short:&nbsp;</strong>
            </em>
            <em>
              Yes, if you are a resident of California, you are granted specific
              rights regarding access to your personal information.
            </em>
          </p>
          <p id="e0e5b121-7d64-4268-a76a-d37381cd8274" className="">
            California Civil Code Section 1798.83, also known as the "Shine The
            Light" law, permits our users who are California residents to
            request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year. If
            you are a California resident and would like to make such a request,
            please submit your request in writing to us using the contact
            information provided below.
          </p>
          <p id="19b5ae9c-6115-41c4-ac51-d6427ed45b88" className="">
            If you are under 18 years of age, reside in California, and have a
            registered account with Services, you have the right to request
            removal of unwanted data that you publicly post on the Services. To
            request removal of such data, please contact us using the contact
            information provided below and include the email address associated
            with your account and a statement that you reside in California. We
            will make sure the data is not publicly displayed on the Services,
            but please be aware that the data may not be completely or
            comprehensively removed from all our systems (e.g., backups, etc.).
          </p>
          <p id="2c467999-08f0-45a2-8c14-2bb2f7eaaded" className="">
            <strong>CCPA Privacy Notice</strong>
          </p>
          <p id="8f4323a0-22a0-4b82-a84b-186085de81c4" className="">
            The California Code of Regulations defines a "resident" as:
          </p>
          <p id="8ee73663-9a03-46ac-951a-bf2206aafb7c" className="">
            (1) every individual who is in the State of California for other
            than a temporary or transitory purpose and
          </p>
          <p id="6bbf380b-cafb-44bd-8b89-700569c037de" className="">
            (2) every individual who is domiciled in the State of California who
            is outside the State of California for a temporary or transitory
            purpose
          </p>
          <p id="6303f6e0-2fd2-49cf-9efa-fc42a7bbe415" className="">
            All other individuals are defined as "non-residents."
          </p>
          <p id="b032bde6-45b9-4954-9607-cfb31c7283e2" className="">
            If this definition of "resident" applies to you, we must adhere to
            certain rights and obligations regarding your personal information.
          </p>
          <p id="f745bbbc-0990-488f-8350-9bc2e01c6ee2" className="">
            <strong>
              What categories of personal information do we collect?
            </strong>
          </p>
          <p id="6b3fbb2f-bb1f-4176-9404-dd324e36495f" className="">
            We have collected the following categories of personal information
            in the past twelve (12) months:
          </p>
          <table
            id="0435b4d0-f082-423b-ad28-1cfdf9f0c4df"
            className="simple-table"
          >
            <tbody>
              <tr id="b74bbc97-8a92-4386-8b61-df6a73bafa32">
                <td id="}Jp:" className="">
                  <strong>Category</strong>
                </td>
                <td id="ON[l" className="">
                  <strong>Examples</strong>
                </td>
                <td id="ETXv" className="">
                  <strong>Collected</strong>
                </td>
              </tr>
              <tr id="cbe242d0-ce80-4d68-8f34-d973bfe5675b">
                <td id="}Jp:" className="">
                  A. Identifiers
                </td>
                <td id="ON[l" className="">
                  Contact details, such as real name, alias, postal address,
                  telephone or mobile contact number, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address, and account name
                </td>
                <td id="ETXv" className="">
                  NO
                </td>
              </tr>
              <tr id="366339dd-8402-464f-b647-8dca5fa40e09">
                <td id="}Jp:" className="">
                  B. Personal information categories listed in the California
                  Customer Records statute
                </td>
                <td id="ON[l" className="">
                  Name, contact information, education, employment, employment
                  history, and financial information
                </td>
                <td id="ETXv" className="">
                  NO
                </td>
              </tr>
              <tr id="04c6ec56-5a38-47a3-80fd-e363a8d43ea7">
                <td id="}Jp:" className="">
                  C. Protected classification characteristics under California
                  or federal law
                </td>
                <td id="ON[l" className="">
                  Gender and date of birth
                </td>
                <td id="ETXv" className="">
                  NO
                </td>
              </tr>
              <tr id="a61ae896-6faa-4df0-b7ac-d7b2b5805db1">
                <td id="}Jp:" className="">
                  D. Commercial information
                </td>
                <td id="ON[l" className="">
                  Transaction information, purchase history, financial details,
                  and payment information
                </td>
                <td id="ETXv" className="">
                  NO
                </td>
              </tr>
              <tr id="1fbcee81-88f5-461d-a259-c94cd1cff098">
                <td id="}Jp:" className="">
                  E. Biometric information
                </td>
                <td id="ON[l" className="">
                  Fingerprints and voiceprints
                </td>
                <td id="ETXv" className="">
                  NO
                </td>
              </tr>
              <tr id="1cb3020a-a527-4216-9f5f-0db621f22961">
                <td id="}Jp:" className="">
                  F. Internet or other similar network activity
                </td>
                <td id="ON[l" className="">
                  Browsing history, search history, online behavior, interest
                  data, and interactions with our and other websites,
                  applications, systems, and advertisements
                </td>
                <td id="ETXv" className="">
                  NO
                </td>
              </tr>
              <tr id="6bdcfa1e-c6b6-4b87-b38b-4f4a17a6e777">
                <td id="}Jp:" className="">
                  G. Geolocation data
                </td>
                <td id="ON[l" className="">
                  Device location
                </td>
                <td id="ETXv" className="">
                  NO
                </td>
              </tr>
              <tr id="6f16380c-8fea-470c-9eb7-9e175c32e8a9">
                <td id="}Jp:" className="">
                  H. Audio, electronic, visual, thermal, olfactory, or similar
                  information
                </td>
                <td id="ON[l" className="">
                  Images and audio, video or call recordings created in
                  connection with our business activities
                </td>
                <td id="ETXv" className="">
                  NO
                </td>
              </tr>
              <tr id="7a013098-b6d3-41d4-8ccd-0cc3ed196d0d">
                <td id="}Jp:" className="">
                  I. Professional or employment-related information
                </td>
                <td id="ON[l" className="">
                  Business contact details in order to provide you our Services
                  at a business level or job title, work history, and
                  professional qualifications if you apply for a job with us
                </td>
                <td id="ETXv" className="">
                  NO
                </td>
              </tr>
              <tr id="8bd5bf52-91c1-42cc-ac66-d14346713d78">
                <td id="}Jp:" className="">
                  J. Education Information
                </td>
                <td id="ON[l" className="">
                  Student records and directory information
                </td>
                <td id="ETXv" className="">
                  NO
                </td>
              </tr>
              <tr id="c6583725-0115-41a4-95af-3e7edfdcd74d">
                <td id="}Jp:" className="">
                  K. Inferences drawn from other personal information
                </td>
                <td id="ON[l" className="">
                  Inferences drawn from any of the collected personal
                  information listed above to create a profile or summary about,
                  for example, an individual’s preferences and characteristics
                </td>
                <td id="ETXv" className="">
                  YES
                </td>
              </tr>
              <tr id="6ac7abe7-80f5-4f88-91bc-8c45407ab7ba">
                <td id="}Jp:" className="">
                  L. Sensitive Personal Information
                </td>
                <td id="ON[l" className="">
                  Account login information, contents of email or text messages
                  and sex life or sexual orientation
                </td>
                <td id="ETXv" className="">
                  YES
                </td>
              </tr>
            </tbody>
          </table>
          <p id="142cc27d-8b89-4c57-b473-f8a0299dcbca" className="">
            We will use and retain the collected personal information as needed
            to provide the Services or for:
          </p>
          <ul
            id="04361322-72d2-4a2d-b04d-d1bc108ec4c1"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              Category K - As long as the user has an account with us
            </li>
          </ul>
          <ul
            id="b3da89d5-23de-4c85-afc5-f8f39580fd5d"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              Category L - As long as the user has an account with us
            </li>
          </ul>
          <p id="48165050-e35c-4361-8b2a-35eaf448584c" className="">
            Category L information may be used, or disclosed to a service
            provider or contractor, for additional, specified purposes. You have
            the right to limit the use or disclosure of your sensitive personal
            information.
          </p>
          <p id="22b07c7f-2c94-4fb8-9676-5a660f1bc21f" className="">
            We may also collect other personal information outside of these
            categories through instances where you interact with us in person,
            online, or by phone or mail in the context of:
          </p>
          <ul
            id="955e6c65-8b04-4987-9ddd-c4063bbdac3f"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              Receiving help through our customer support channels;
            </li>
          </ul>
          <ul
            id="702340a7-2402-4d73-a456-138e86db6ba0"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              Participation in customer surveys or contests; and
            </li>
          </ul>
          <ul
            id="f4651877-bfe1-4375-80ea-4dc5e072460c"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              Facilitation in the delivery of our Services and to respond to
              your inquiries.
            </li>
          </ul>
          <p id="5e793727-edd9-4a90-8a69-44a6b09618a3" className="">
            <strong>How do we use and share your personal information?</strong>
          </p>
          <p id="176a906a-9a8c-4ac2-8656-6f211e9b369e" className="">
            More information about our data collection and sharing practices can
            be found in this privacy notice.
          </p>
          <p id="4c086fc2-a8b2-4b1c-b8d0-86316e5a6202" className="">
            You may contact us by email at&nbsp;beyondclass.business@gmail.com,
            or by referring to the contact details at the bottom of this
            document.
          </p>
          <p id="0d70fa84-38b0-4090-a8a5-fa1b99f19771" className="">
            If you are using an authorized agent to exercise your right to opt
            out we may deny a request if the authorized agent does not submit
            proof that they have been validly authorized to act on your behalf.
          </p>
          <p id="d20c6b43-d4fb-4031-b6d8-01876455acad" className="">
            <strong>Will your information be shared with anyone else?</strong>
          </p>
          <p id="39259d77-0a46-43f5-a9bb-43817d7d0c32" className="">
            We may disclose your personal information with our service providers
            pursuant to a written contract between us and each service provider.
            Each service provider is a for-profit entity that processes the
            information on our behalf, following the same strict privacy
            protection obligations mandated by the CCPA.
          </p>
          <p id="7fa44d67-6100-4595-b38b-b37f9d0000bd" className="">
            We may use your personal information for our own business purposes,
            such as for undertaking internal research for technological
            development and demonstration. This is not considered to be
            "selling" of your personal information.
          </p>
          <p id="dd609000-74e6-4a87-bf52-f8358f9c0e00" className="">
            BeyondClass&nbsp;has not disclosed, sold, or shared any personal
            information to third parties for a business or commercial purpose in
            the preceding twelve (12) months. BeyondClass&nbsp;will not sell or
            share personal information in the future belonging to website
            visitors, users, and other consumers.
          </p>
          <p id="ddea2897-b9c8-46ee-a5c2-9f1e35eda8ab" className="">
            <strong>Your rights with respect to your personal data</strong>
          </p>
          <p id="fadc4bf2-02ae-49dc-be46-a9414f0c078a" className="">
            Right to request deletion of the data — Request to delete
          </p>
          <p id="49e936d5-fb94-41a5-a1cc-774fbf9c9fe9" className="">
            You can ask for the deletion of your personal information. If you
            ask us to delete your personal information, we will respect your
            request and delete your personal information, subject to certain
            exceptions provided by law, such as (but not limited to) the
            exercise by another consumer of his or her right to free speech, our
            compliance requirements resulting from a legal obligation, or any
            processing that may be required to protect against illegal
            activities.
          </p>
          <p id="af49e089-1c89-43b7-9d80-e39d973af0b9" className="">
            Right to be informed — Request to know
          </p>
          <p id="44ba89e4-fb6e-4788-98aa-de7d1c3db19b" className="">
            Depending on the circumstances, you have a right to know:
          </p>
          <ul
            id="479a4076-6da8-4efa-96ea-67439195c834"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              whether we collect and use your personal information;
            </li>
          </ul>
          <ul
            id="b31fbaf5-8bd6-47b1-97ef-525d5a3aef6e"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              the categories of personal information that we collect;
            </li>
          </ul>
          <ul
            id="80dc6e6f-8581-4b56-b541-25d07ddd0132"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              the purposes for which the collected personal information is used;
            </li>
          </ul>
          <ul
            id="5c6a65c6-2b4f-4600-97c4-cec9435b2a12"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              whether we sell or share personal information to third parties;
            </li>
          </ul>
          <ul
            id="9471fc57-80e5-45f1-b20e-1732e556ff60"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              the categories of personal information that we sold, shared, or
              disclosed for a business purpose;
            </li>
          </ul>
          <ul
            id="65b5e775-5cef-46e0-9ec0-02cab1c5654f"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              the categories of third parties to whom the personal information
              was sold, shared, or disclosed for a business purpose;
            </li>
          </ul>
          <ul
            id="9ea5674d-cbe4-498f-8b0a-1a2b2124a526"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              the business or commercial purpose for collecting, selling, or
              sharing personal information; and
            </li>
          </ul>
          <ul
            id="c1b34726-fb78-4c72-bf45-d93a867b47c0"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              the specific pieces of personal information we collected about
              you.
            </li>
          </ul>
          <p id="a6a1c407-fe72-449a-a38f-bedbf4fe965a" className="">
            In accordance with applicable law, we are not obligated to provide
            or delete consumer information that is de-identified in response to
            a consumer request or to re-identify individual data to verify a
            consumer request.
          </p>
          <p id="504e7fcf-402f-4863-8649-4b6623fe9830" className="">
            Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
            Rights
          </p>
          <p id="63bad831-a127-4192-875a-c0c9e2c51072" className="">
            We will not discriminate against you if you exercise your privacy
            rights.
          </p>
          <p id="04625567-98ea-42c9-929e-d442d6937d8d" className="">
            Right to Limit Use and Disclosure of Sensitive Personal Information
          </p>
          <p id="b0e4785d-21c4-47a6-8cfa-6e2df7f9c9c7" className="">
            If the business collects any of the following:
          </p>
          <ul
            id="2244390f-1730-4793-8887-017573bc3a2d"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              social security information, drivers' licenses, state ID cards,
              passport numbers
            </li>
          </ul>
          <ul
            id="f1e52c0b-cbe7-4378-a62c-53d055ff462f"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>account login information</li>
          </ul>
          <ul
            id="ac0821af-40de-4e63-9f9a-d2dd6b5632a7"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              credit card numbers, financial account information, or credentials
              allowing access to such accounts
            </li>
          </ul>
          <ul
            id="b6396bc3-6ae1-46e2-ac6f-8e53f9fc55f6"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>precise geolocation</li>
          </ul>
          <ul
            id="c2ff84da-3235-46aa-b1fc-05808d4d0030"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              racial or ethnic origin, religious or philosophical beliefs, union
              membership
            </li>
          </ul>
          <ul
            id="880b76df-e3f0-45e5-b7ad-68b91fc882a4"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              the contents of email and text, unless the business is the
              intended recipient of the communication
            </li>
          </ul>
          <ul
            id="1746ae5f-78c0-46f5-b2c2-5aa1049d94a8"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              genetic data, biometric data, and health data
            </li>
          </ul>
          <ul
            id="3fb287b2-4d82-4963-afc2-0a5808299f27"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              data concerning sexual orientation and sex life
            </li>
          </ul>
          <p id="1be3a709-b9b0-4ddb-99ab-487ec0dc1e12" className="">
            you have the right to direct that business to limit its use of your
            sensitive personal information to that use which is necessary to
            perform the Services.
          </p>
          <p id="52c0a74a-319d-45e0-aa17-7557d2c4af86" className="">
            Once a business receives your request, they are no longer allowed to
            use or disclose your sensitive personal information for any other
            purpose unless you provide consent for the use or disclosure of
            sensitive personal information for additional purposes.
          </p>
          <p id="7da0bed2-9997-45dd-8bad-3558dcfbf7d5" className="">
            Please note that sensitive personal information that is collected or
            processed without the purpose of inferring characteristics about a
            consumer is not covered by this right, as well as the publicly
            available information.
          </p>
          <p id="6835b117-472e-4b2e-b256-4e5691afd5dc" className="">
            To exercise your right to limit use and disclosure of sensitive
            personal information, please email beyondclass.privacy@gmail.com or
            submit a request form by clicking{" "}
            <a href="https://app.termly.io/notify/ad2ea2cf-a6f9-4cc1-8670-5a6530b6e4ce">
              here
            </a>
            .
          </p>
          <p id="9e33bbc0-67d4-47aa-a80d-37f839726432" className="">
            Verification process
          </p>
          <p id="31bfd635-9a31-47aa-b2a5-4e6d95563c7a" className="">
            Upon receiving your request, we will need to verify your identity to
            determine you are the same person about whom we have the information
            in our system. These verification efforts require us to ask you to
            provide information so that we can match it with information you
            have previously provided us. For instance, depending on the type of
            request you submit, we may ask you to provide certain information so
            that we can match the information you provide with the information
            we already have on file, or we may contact you through a
            communication method (e.g., phone or email) that you have previously
            provided to us. We may also use other verification methods as the
            circumstances dictate.
          </p>
          <p id="689e2d6a-fe02-44c8-bcb9-c893c98018e2" className="">
            We will only use personal information provided in your request to
            verify your identity or authority to make the request. To the extent
            possible, we will avoid requesting additional information from you
            for the purposes of verification. However, if we cannot verify your
            identity from the information already maintained by us, we may
            request that you provide additional information for the purposes of
            verifying your identity and for security or fraud-prevention
            purposes. We will delete such additionally provided information as
            soon as we finish verifying you.
          </p>
          <p id="16e528c8-ffb7-4471-94fb-8054d703db06" className="">
            Other privacy rights
          </p>
          <ul
            id="730b1055-44bd-4b42-9bbf-cac9a55d8798"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              You may object to the processing of your personal information.
            </li>
          </ul>
          <ul
            id="fdcb0d16-0ff6-4164-b7d7-b86e1fb8c878"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              You may request correction of your personal data if it is
              incorrect or no longer relevant, or ask to restrict the processing
              of the information.
            </li>
          </ul>
          <ul
            id="3ee13cd2-97d1-46c6-86c5-91d5c7dd0d64"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              You can designate an authorized agent to make a request under the
              CCPA on your behalf. We may deny a request from an authorized
              agent that does not submit proof that they have been validly
              authorized to act on your behalf in accordance with the CCPA.
            </li>
          </ul>
          <ul
            id="967fa9ae-7130-42b6-a4fd-ab2d7610ad81"
            className="bulleted-list"
          >
            <li style={{ listStyleType: "disc" }}>
              You may request to opt out from future selling or sharing of your
              personal information to third parties. Upon receiving an opt-out
              request, we will act upon the request as soon as feasibly
              possible, but no later than fifteen (15) days from the date of the
              request submission.
            </li>
          </ul>
          <p id="5e9a915c-18ff-4df1-8c1f-7c7ca6151488" className="">
            To exercise these rights, you can contact us&nbsp;by email at
            beyondclass.business@gmail.com, or by referring to the contact
            details at the bottom of this document. If you have a complaint
            about how we handle your data, we would like to hear from you.
          </p>
          <p id="72fafa1a-c1c0-4cdd-9f45-acbd2bbf351e" className=""></p>
          <h3 id="7c33d3dc-ea4a-4836-891c-1ef37a3a3094" className="">
            11. <strong>DO WE MAKE UPDATES TO THIS NOTICE?</strong>
          </h3>
          <p id="dc43d42c-031b-423b-b3bb-6951483cb5c1" className="">
            <em>
              <strong>In Short:&nbsp;</strong>
            </em>
            <em>
              Yes, we will update this notice as necessary to stay compliant
              with relevant laws.
            </em>
          </p>
          <p id="b3c675ad-5307-4364-8ff2-7479f46f437d" className="">
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated "Revised" date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </p>
          <p id="7adfd370-5275-47f6-ab13-35fd55317411" className=""></p>
          <h3 id="66af41d7-f1d7-4f43-978d-022966e296aa" className="">
            12. <strong>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong>
          </h3>
          <p id="bf5b266f-bc6a-479e-8a89-b2a3c2de5290" className="">
            If you have questions or comments about this notice, you may contact
            our Data Protection Officer (DPO) &nbsp;by email at&nbsp;
            <mark className="highlight-gray">
              <span style={{ borderBottom: "0.05em solid" }}>
                beyondclass.privacy@gmail.com
              </span>
            </mark>
            ,&nbsp;or by post to:
          </p>
          <p id="3076166f-3643-48a1-9133-ba771048ea3d" className="">
            BeyondClass
          </p>
          <p id="cd0aefa8-950a-4720-a1f7-32101c576e03" className="">
            123 S Burrowes St
          </p>
          <p id="a499f073-c528-4fb1-a7b0-027f92be411a" className="">
            2nd Floor
          </p>
          <p id="55ee13e6-4c22-4407-aa62-9ea04cc0d8ed" className="">
            State College, PA 16801
          </p>
          <p id="584baed2-4402-433f-85cd-d014f22ae731" className="">
            United States
          </p>
          <p id="d4595422-1bce-47c2-ab64-dabfb9c38c75" className=""></p>
          <h3 id="2de9409c-a691-46da-b7b3-158a7b439b2c" className="">
            13.{" "}
            <strong>
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </strong>
          </h3>
          <p id="9d3712d8-9166-4bfc-83b5-44433451cb4c" className="">
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it. To request to review, update,
            or delete your personal information, please submit a request form by
            clicking&nbsp;
            <a href="https://app.termly.io/notify/ad2ea2cf-a6f9-4cc1-8670-5a6530b6e4ce">
              here
            </a>
            .
          </p>
        </div>
      </article>
    </>
  );
};

export default Privacy;
