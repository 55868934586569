import * as React from "react";
import { useState } from "react";
import { useRef } from "react";
import "./SearchBar.scss";
import UserInput from "common/UserInput/UserInput.jsx";
import SearchIcon from "@mui/icons-material/Search";

function SearchBar() {
  const targetRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const showSearchInput = isHovered || isFocused;

  return (
    <div className="searchBar-objects-wrapper">
      <label className="search-icon-content">
        <SearchIcon width={20} height={20} />
      </label>
      <UserInput type="text">[Upcoming!] Start your search today</UserInput>
    </div>
  );
}

export default SearchBar;
