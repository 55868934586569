import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import IconButton from 'common/Button/IconButton/IconButton';
import "./ScrollButton.scss"

function ScrollButton() {
    const onClickHandler = () => {
        window.scrollTo({
            top:0,
            behavior: "smooth"
        });
      };
    return(
        <>
            <IconButton
                className = "scroll-button"
                onclick = {onClickHandler}
                Icon={KeyboardDoubleArrowUpIcon}/>
        </>
    )
}

export default ScrollButton;