// import react modules
import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
// import scss stylesheet
import "./UserLogin.scss";
// import component dependencies
import IconWithLabelButton from "common/Button/IconWithLabelButton/IconWithLabelButton";
import LabelButton from "common/Button/LabelButton/LabelButton";
import HyperLink from "common/HyperLink/HyperLink";
import AuthForm from "pages/LogIn/components/AuthForm/AuthForm";
// import icons
import beyondClassLogoVertical from "assets/brand/beyondClassLogoVertical.svg";
import { ReactComponent as GoogleIcon } from "assets/brand/google/web/vector/btn_google_light_normal_ios.svg";
// firebase integration
import { authService } from "setup/firebase/fbase";
import { useNavigate } from "react-router-dom";

const UserLogin = () => {
  // toggle login methods
  let [thirdParty, setThirdParty] = useState(false);
  const onFlip = () => {
    setThirdParty((current) => !current);
    setError("");
  };
  const navigate = useNavigate();
  // sso email
  const [email, setEmail] = useState("");
  // sso password
  const [password, setPassword] = useState("");
  // sso error
  const [error, setError] = useState("");
  // form
  const onChange = (event) => {
    const {
      target: { name, value },
    } = event;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };
  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      await authService.signInWithEmailAndPassword(email, password);
      navigate("/competition");
    } catch (error) {
      setError(
        "We're sorry 😥" +
          error.message
            .replace("Firebase:", "")
            .replace("(".concat(error.code, ")."), "")
      );
    }
  };
  return (
    /* Page wrapper */
    <>
      {/* Main content */}
      <main className="login">
        <div className="login-large-wrapper">
          <div className="bc-logo-link">
            <Link to="/">
              <img src={beyondClassLogoVertical} alt="logo"></img>
            </Link>
          </div>
          <h1 className="title-content">Welcome! 🔑</h1>
          <section className="main-wrapper">
            <div className="login-form-wrapper">
              {thirdParty ? (
                // hide other login options
                <>
                  <IconWithLabelButton
                    name="button"
                    Icon={GoogleIcon}
                    iconWidth={24}
                    iconHeight={24}
                    iconFill={"white"}
                    type={"submit"}
                    className="google-login-form"
                  >
                    Continue with Google
                  </IconWithLabelButton>
                </>
              ) : (
                // show other login options
                <AuthForm
                  email={email}
                  password={password}
                  button_name={"Sign up for waitlist"}
                  onSubmit={onSubmit}
                  onChange={onChange}
                />
              )}
            </div>
            <LabelButton className="toggle-thirdparty" onClick={onFlip}>
              {thirdParty ? "Sign in with email" : "Other sign in options"}
            </LabelButton>
            {error === "" ? (
              <></>
            ) : (
              <div className="user-signin-border">
                <div className="error-msgbox"> {error}</div>
                <HyperLink linkTo={"/"} className={"link-to-troubleshoot"}>
                  Cannot Sign in?
                </HyperLink>
              </div>
            )}
          </section>
          <section className="addops-wrapper">
            <label>Don't have an account?</label>
            <HyperLink linkTo={"/signup"} className={"link-to-signup"}>
              Create yours now.
            </HyperLink>
          </section>
        </div>
      </main>
    </>
  );
};

export default UserLogin;
