import React from "react";
import Template from "../components/Template/Template";
import tempdata from "tempdata/tempdata"

function Competition() {
  let squareList = [];
  for (let i = 0; i < tempdata.length; i++){
    if (tempdata[i].eventtype === "competition") {
      squareList.push(tempdata[i]);
    }
  }
  return (
    <>
      <Template
        pageTitle="Competitions"
        shortDescription="Choose the competition just right for you."
        squareData={squareList}
      />
    </>
  );
}

export default Competition;
