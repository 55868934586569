import React from 'react';


function NetwTitle({titleData}) {

    return (
        <div className = "title-rectangle-wrapper">
          <div className = "title-content-wrapper">
            <div className = "title-text-content">{titleData.name}</div>
            <div className = "title-requirement-wrapper">
              <div className = "requirement-wrapper">
                <div className = "requirement-title-content">Attire: </div> 
                <span className = "requirement-input-content">{titleData.attire}</span>
              </div>
              <div className = "requirement-wrapper">
                <div className = "requirement-title-content">Event Date: </div> 
                <div className = "requirement-input-content">{titleData.deadline[0]}</div>
              </div>
              <div className = "requirement-wrapper">
                <div className = "requirement-title-content">Location: </div>
                <div className = "requirement-input-content">{titleData.location}</div>
              </div>
              <div className = "requirement-wrapper">
                <div className = "requirement-title-content">Who: </div>
                <div className = "requirement-input-content">{titleData.eligibility[1]}</div>
              </div>
              <div className = "requirement-wrapper">
                <div className = "requirement-title-content">Type: </div>
                <div className = "requirement-input-content">{titleData.networktype}</div>
              </div>
              <div className = "requirement-wrapper">
                <div className = "requirement-title-content">Host: </div>
                <div className = "requirement-input-content">{titleData.host[0]}</div>
              </div>
            </div>
          </div>
        </div>
    )
}

export default NetwTitle;