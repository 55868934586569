import React from "react";
import "./FooterHeadings.scss";

// this component generates a collection of footer headings
const FooterHeadings = ({ section }) => {
  return (
    <>
      {section.map((section) => (
        <span className="footer-subname" key={section.id}>
          {section.name}
        </span>
      ))}
    </>
  );
};

export default FooterHeadings;
