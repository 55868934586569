import React from "react";
import PropTypes from "prop-types";
import IconButton from "common/Button/IconButton/IconButton";
import "./Square.scss";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Link } from "react-router-dom";


function Square({ data, classname, size }) {
  let first;
  let second;
  let info1;
  let info2;
  if (data.eventtype === "competition") {
    first = "Time";
    info1 = data.timeline[0][0][0];
    second = "Location";
    info2 = data.location;
  } else if (data.eventtype === "scholarship") {
    first = "Awards";
    info1 = data.award[1] + " $" + data.award[0];
    second = "Deadline";
    info2 = data.deadline;
  } else if (data.eventtype === "network") {
    first = "Type";
    info1 = data.networktype;
    second = "When";
    info2 = data.deadline[0];
  }
  const onclickHandler = (name) => alert(`Sorry, we will be back soon with "${name}" function!`);
  return (
        <div className={`square-component-wrapper square-component-${size}`} id = {classname}>
          <Link className="link-wrapper" to ={"/".concat(data.eventtype+"/"+data.id)}>
            <div className="square-event-title">
              {data.name}
            </div>
            <div className="square-subinfo">
              <span>{data.host[0]}</span>
            </div>
            <div className="square-rowA">
              <div className="square-heading">
                <span>{first}</span>
              </div>
              <div className="square-info">
                <span>{info1}</span>
              </div>
            </div>
            <div className="square-rowB">
              <div className="square-heading">{second}</div>
              <div className="square-info">{info2}</div>
            </div>
          </Link>
          <div className = "button-wrapper">
            <IconButton
              className="calendar-button"
              Icon={CalendarMonthIcon}
              iconHeight={25}
              iconFill={"black"}
              onclick={() => onclickHandler("Calendar")}
            />
            <IconButton
              className="notify-button"
              Icon={NotificationsNoneIcon}
              iconHeight={25}
              iconFill={"black"}
              onclick={() => onclickHandler("Notification")}
            />
          </div>
        </div>
  );
}


function Color({classname}) {
  if (classname.includes("Engineering")) {
    document.getElementById("College of Engineering").style.border = "1px solid red";
  } else if (classname.includes("Agricultural")){
    document.getElementById("College of Agricultural Science").style.border = "1px solid green";
  } else {
    console.log(30);
  }
}

Square.defaultProps = {
  linkTo: "/events",
  size: "medium",
};

Square.propTypes = {
  linkTo: PropTypes.string,
  size: PropTypes.string,
};

export default Square;
