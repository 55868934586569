import React from "react";
import { Link } from "react-router-dom";
import HyperLink from "common/HyperLink/HyperLink";
import LabelButton from "common/Button/LabelButton/LabelButton.jsx";
import SearchBar from "common/SearchBar/SearchBar.jsx";
import "./TopBar.scss";
import beyondclasslogo from "assets/brand/beyondClassLogo.svg";
import { authService } from "setup/firebase/fbase";

function getGreetingMsg() {
  let msg = "";
  let today = new Date();
  let hour = today.getHours();
  if (hour < 12) {
    msg = "Good morning";
  } else if (hour < 18) {
    msg = "Good afternoon";
  } else {
    msg = "Good evening";
  }
  return msg;
}

function TopBar({ isLoggedIn }) {
  const onLogOutClick = () => authService.signOut();
  const onJoinUsClick = () => {
    console.log("submit");
    window.open("https://forms.gle/TtmKH4bnSZLFSXp78");
  };
  return (
    <div className="topbar-wrapper">
      <div className="topbar-objects-wrapper">
        <Link to="/">
          <img
            className="footer-logo"
            src={beyondclasslogo}
            alt="logo"
            height="20rem"
          ></img>
        </Link>
        <SearchBar />
        <div className="topbar-buttons-wrapper">
          {isLoggedIn ? (
            <>
              <HyperLink linkTo="/signup" className="auth-button-content">
                <LabelButton>
                  {getGreetingMsg()}, {authService.currentUser.email}
                  {" 😊"}
                </LabelButton>
              </HyperLink>
              <div className="auth-button-content">
                <LabelButton onClick={onLogOutClick}>Log out</LabelButton>
              </div>
            </>
          ) : (
            <>
              <HyperLink linkTo="" className="auth-button-content">
                <LabelButton onclick = {() => onJoinUsClick()}>Join us</LabelButton>
              </HyperLink>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TopBar;
