import React from "react";
import Template from "../components/Template/Template";
import tempdata from "tempdata/tempdata"

function NetworkEvents() {
  let squareList = [];
  for (let i = 0; i < tempdata.length; i++){
    if (tempdata[i].eventtype == "network") {
      squareList.push(tempdata[i]);
    }
  }
  return (
    <>
      <Template 
      pageTitle = "Networking Events"
      shortDescription = "Choose the network event just right for you."
      squareData={squareList}
      />
    </>
  );
}

export default NetworkEvents;
 