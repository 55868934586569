import React from "react";
import HyperLink from "common/HyperLink/HyperLink";
import "./SquareName.scss";

function SquareName({ category }) {
  return (
    <div className="squareName">
      <div className="squareName-title-content">{category.name}</div>
      <HyperLink linkTo={category.linkTo}>Learn More</HyperLink>
    </div>
  );
}

export default SquareName;
