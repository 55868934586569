import React, { useState } from "react";
import LabelButton from "common/Button/LabelButton/LabelButton";

function CompDesc({ descriptionData }) {
  const [message, setMessage] = useState(
    <div>
      <h3>Name:</h3>
      {descriptionData.name}
      <h3>Location:</h3>
      {descriptionData.location}
      <h3>Timeline:</h3>
      First Event: {descriptionData.timeline[0][0][0]} {descriptionData.timeline[0][0][1]} ~ {descriptionData.timeline[0][1][0]} {descriptionData.timeline[0][1][1]}<br/>
      Second Event: {descriptionData.timeline[1][0][0]} {descriptionData.timeline[1][0][1]} ~ {descriptionData.timeline[1][1][0]} {descriptionData.timeline[1][1][1]}<br/>
      Third Event: {descriptionData.timeline[2][0][0]} {descriptionData.timeline[2][0][1]} ~ {descriptionData.timeline[2][1][0]} {descriptionData.timeline[2][1][1]}<br/>
      <h3>Prize:</h3>
      {descriptionData.prize[2]}
      <h3>Host:</h3>
      {descriptionData.host[0]}
      <h3>Apply Method:</h3>
      {descriptionData.apply}
      <h3>Description:</h3>
      {descriptionData.overview}
    </div>
  );
  const onClickCategory1 = () => setMessage(
    <div>
      <h3>Name:</h3>
      {descriptionData.name}
      <h3>Location:</h3>
      {descriptionData.location}
      <h3>Timeline:</h3>
      First Event: {descriptionData.timeline[0][0][0]} {descriptionData.timeline[0][0][1]} ~ {descriptionData.timeline[0][1][0]} {descriptionData.timeline[0][1][1]}<br/>
      Second Event: {descriptionData.timeline[1][0][0]} {descriptionData.timeline[1][0][1]} ~ {descriptionData.timeline[1][1][0]} {descriptionData.timeline[1][1][1]}<br/>
      Third Event: {descriptionData.timeline[2][0][0]} {descriptionData.timeline[2][0][1]} ~ {descriptionData.timeline[2][1][0]} {descriptionData.timeline[2][1][1]}<br/>
      <h3>Prize:</h3>
      {descriptionData.prize[2]}
      <h3>Host:</h3>
      {descriptionData.host[0]}
      <h3>Apply Method:</h3>
      {descriptionData.apply}
      <h3>Description:</h3>
      {descriptionData.overview}
    </div>
  );
  const onClickCategory2 = () => setMessage(
    <div>
      <h3>UnderGrad/Grad:</h3>
      {descriptionData.eligibility[0]}
      <h3>College:</h3>
      {descriptionData.eligibility[1]}
      <h3>Major:</h3>
      {descriptionData.eligibility[2]}
      <h3>Team:</h3>
      {descriptionData.eligibility[3]}
      <h3>Other:</h3>
      {descriptionData.eligibility[4]}
      <h3>Additional:</h3>
      {descriptionData.eligibility[5]}
    </div>
  );
  const onClickCategory3 = () => setMessage(
    <div>
      <h3>Contacts:</h3>
      {descriptionData.host[1]}
      <h3>Links:</h3>
      <a href={descriptionData.link}>Event Link</a><br/>
    </div>
  );
  return (
    <div className="description-rectangle-wrapper">
        <section className="description-category-wrapper">
          <div className="category-items-wrapper">
            <LabelButton onclick={onClickCategory1}>
              Overview
            </LabelButton>
          </div>
          <div className="category-items-wrapper">
            <LabelButton onclick={onClickCategory2}>
              Conditions
            </LabelButton>
          </div>
          <div className="category-items-wrapper">
            <LabelButton onclick={onClickCategory3}>
              Contacts
            </LabelButton>
          </div>
        </section>
        <div className="description-content">{message}</div>
    </div>
  );
}

export default CompDesc;
