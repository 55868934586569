import React from "react";
import "./EventDetail.scss";
import Title from "pages/EventDetail/components/Title/Title.jsx";
import Description from "pages/EventDetail/components/Description/Description.jsx";
import ProfileBox from "common/ProfileBox/LogInProfileBox/LogInProfileBox.jsx";
import { useParams } from "react-router-dom";
import tempdata from "tempdata/tempdata";

function EventDetail() {
  const { productId } = useParams();
  const onclickHandler = (name) => alert(`Sorry, we will be back soon with "${name}" function!`);
  return (
    <>
      <main id="EventDetail">
        <div className="eventdetail-items-wrapper">
          <div className="information-wrapper">
            <Title className="title" titleData={tempdata[productId]} />
            <Description descriptionData={tempdata[productId]} />
          </div>
          <div className="profileBox-wrapper">
            <ProfileBox onclick = {() => onclickHandler("Profile")}/>
          </div>
        </div>
      </main>
    </>
  );
}
export default EventDetail;
