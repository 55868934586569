import React from "react";
import Template from "../components/Template/Template";
import tempdata from "tempdata/tempdata"

function Scholarships() {
  let squareList = [];
  for (let i = 0; i < tempdata.length; i++){
    if (tempdata[i].eventtype === "scholarship") {
      squareList.push(tempdata[i]);
    }
  }
  return (
    <>
      <Template 
      pageTitle = "Scholarships"
      shortDescription = "Choose the scholarship just right for you."
      squareData={squareList}
      />
    </>
  );
}

export default Scholarships;
 