import HyperLink from "common/HyperLink/HyperLink";
import { redirect } from "react-router-dom";

import "./FooterCategories.scss";

// this component generates a collection of footer subcategories given a subcategory list
const FooterSubCategories = ({ subcategory }) => {
  return (
    <div className="footer-subcategories">
      {subcategory.map((subcategory) => (
        <HyperLink
          linkTo={subcategory.link}
          className="footer-subcategory-text"
          key={subcategory.sid}
          linkTarget={subcategory.target}
          onClick={() => {
            window.open(subcategory.link);
            }}
        >
          {subcategory.name}
        </HyperLink>
      ))}
    </div>
  );
};

// this component generates a collection of subcategory wrappers with respect to each category
const FooterCategories = ({ section }) => {
  return (
    <>
      {section.map((category) => (
        <FooterSubCategories
          subcategory={category.subcategory}
          key={category.id}
        />
      ))}
    </>
  );
};

export default FooterCategories;
