import React from "react";
import PropTypes from "prop-types";
import "common/Button/LabelButton/LabelButton.scss";

const LabelButton = ({ className, children, onclick }) => {
  return (
    <button
      className={`jsx-button label-button ${className}`}
      onClick={onclick}
    >
      <span className="button-content">{children}</span>
    </button>
  );
};

LabelButton.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LabelButton;
