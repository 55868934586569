import React, { useState } from "react";
import LabelButton from "common/Button/LabelButton/LabelButton";
import "./Description.scss";
import CompDesc from "./DescPages/CompDesc";
import SchoDesc from "./DescPages/SchoDesc";
import NetwDesc from "./DescPages/NetwDesc"

function Description({ descriptionData })
 {
  let descriptionInfo;
  if (descriptionData.eventtype === "competition") {
    descriptionInfo = <CompDesc descriptionData={descriptionData}/>;
  } else if (descriptionData.eventtype === "scholarship") {
    descriptionInfo = <SchoDesc descriptionData={descriptionData}/>;
  } else {
    descriptionInfo = <NetwDesc descriptionData={descriptionData}/>;
  }
  return(
    <>
    {descriptionInfo}
    </>
  );
}

export default Description;
