import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
// eslint-disable-next-line no-unused-vars
import firebase from "setup/firebase/fbase.js";
import App from "setup/App.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);


