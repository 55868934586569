import React, { useState } from "react";
import LabelButton from "common/Button/LabelButton/LabelButton";

function SchoDesc({ descriptionData }) {
  const [message, setMessage] = useState(
    <div>
      <h3>Name:</h3>
      {descriptionData.name}
      <h3>Deadline:</h3>
      {descriptionData.deadline}
      <h3>Purpose:</h3>
      {descriptionData.purpose}
      <h3>Document:</h3>
      {descriptionData.document}
      <h3>Source:</h3>
      {descriptionData.source}
      <h3>Additional:</h3>
      {descriptionData.overview}
    </div>
  );
  const onClickCategory1 = () => setMessage(
    <div>
      <h3>Name:</h3>
      {descriptionData.name}
      <h3>Deadline:</h3>
      {descriptionData.deadline}
      <h3>Purpose:</h3>
      {descriptionData.purpose}
      <h3>Document:</h3>
      {descriptionData.document}
      <h3>Source:</h3>
      {descriptionData.source}
      <h3>Additional:</h3>
      {descriptionData.overview}
    </div>
  );
  const onClickCategory2 = () => setMessage(
    <div>
      <h3>College:</h3>
      {descriptionData.eligibility[0]}
      <h3>Major:</h3>
      {descriptionData.eligibility[1]}
      <h3>GPA</h3>
      {descriptionData.eligibility[2]}
      <h3>Other:</h3>
      {descriptionData.eligibility[3]}
    </div>
  );
  const onClickCategory3 = () => setMessage(
    <div>
      <h3>Contacts:</h3>
      {descriptionData.host[0]}<br/>
      {descriptionData.host[1]}
      <h3>Links:</h3>
      <a href={descriptionData.eventLink}>Event Link</a><br/>
      <a href={descriptionData.appLink}>Application Link</a>
    </div>
  );
  return (
    <div className="description-rectangle-wrapper">
        <section className="description-category-wrapper">
          <div className="category-items-wrapper">
            <LabelButton onclick={onClickCategory1}>
              Overview
            </LabelButton>
          </div>
          <div className="category-items-wrapper">
            <LabelButton onclick={onClickCategory2}>
              Conditions
            </LabelButton>
          </div>
          <div className="category-items-wrapper">
            <LabelButton onclick={onClickCategory3}>
              Contacts
            </LabelButton>
          </div>
        </section>
        <div className="description-content">{message}</div>
    </div>
  );
}

export default SchoDesc;
