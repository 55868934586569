import React from "react";
// import scss stylesheet
import "./AuthForm.scss";
// import component dependencies
import IconWithLabelButton from "common/Button/IconWithLabelButton/IconWithLabelButton";
import UserInput from "common/UserInput/UserInput";
// import assets
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

const AuthForm = ({ email, password, button_name, onChange, onSubmit }) => {
  const onclickHandler = () => {
    console.log("submit");
    window.open("https://forms.gle/TtmKH4bnSZLFSXp78");
  };
  return (
    <form className="login-form" method="post" onSubmit={onSubmit}>
      <div className="form-control-wrap">
        <UserInput
          name="email"
          value={email}
          type="text"
          className="form-control"
          onChange={onChange}
        >
          Email
        </UserInput>
        <UserInput
          name="password"
          value={password}
          type="password"
          className="form-control"
          onChange={onChange}
        >
          password
        </UserInput>
      </div>
      <IconWithLabelButton
        name="button"
        Icon={EmailOutlinedIcon}
        iconWidth={24}
        iconHeight={24}
        iconFill={"white"}
        type={"submit"}
        className="form-submit"
        onclick={() => onclickHandler("Sign In")}
      >
        {button_name}
      </IconWithLabelButton>
    </form>
  );
};

export default AuthForm;
