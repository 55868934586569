import Privacy from "pages/Privacy/Privacy.jsx";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import different routes from index file
import {
  UserSignUp,
  Calendar,
  UserLogIn,
  Main,
  Settings,
  AboutUs,
  EventDetail,
  Competitions,
  NetworkEvents,
  Scholarships,
  Header,
} from "./index.js";

const AppRouter = ({ isLoggedIn }) => {
  return (
    <Router>
      <Routes>
        {isLoggedIn ? (
          <>
            {["/", "/home"].map((path, index) => {
              return (
                <Route
                  path={path}
                  element={
                    <>
                      <Header isLoggedIn={isLoggedIn} />
                      <Main />
                    </>
                  }
                  key={index}
                />
              );
            })}
            <Route path="/login" element={<UserLogIn />} />
            <Route path="/signup" element={<UserSignUp />} />
            <Route
              path="/settings"
              element={
                <>
                  <Header isLoggedIn={isLoggedIn} />
                  <Settings />
                </>
              }
            />
          </>
        ) : (
          <>
            <Route
              path="/"
              element={
                <>
                  <Header isLoggedIn={isLoggedIn} />
                  <AboutUs />
                </>
              }
            />
            <Route path="/signup" element={<UserSignUp />} />
            <Route path="/login" element={<UserLogIn />} />
            <Route
              path="/calendar"
              element={
                <>
                  <Header />
                  <Calendar />
                </>
              }
            />
            <Route
              path="/competition"
              element={
                <>
                  <Header />
                  <Competitions />
                </>
              }
            />
            <Route
              path="/network"
              element={
                <>
                  <Header />
                  <NetworkEvents />
                </>
              }
            />
            <Route
              path="/scholarship"
              element={
                <>
                  <Header />
                  <Scholarships />
                </>
              }
            />
            <Route
              path="/events"
              element={
                <>
                  <Header />
                  <EventDetail />
                </>
              }
            />
            <Route
              path="/aboutus"
              element={
                <>
                  <Header />
                  <AboutUs />
                </>
              }
            />
          </>
        )}
        <Route
          path="/calendar"
          element={
            <>
              <Header isLoggedIn={isLoggedIn} />
              <Calendar />
            </>
          }
        />
        <Route
          path="/competition"
          element={
            <>
              <Header isLoggedIn={isLoggedIn} />
              <Competitions />
            </>
          }
        />
        <Route
          path="/network"
          element={
            <>
              <Header isLoggedIn={isLoggedIn} />
              <NetworkEvents />
            </>
          }
        />
        <Route
          path="/scholarship"
          element={
            <>
              <Header isLoggedIn={isLoggedIn} />
              <Scholarships />
            </>
          }
        />
        <Route
          path="/competition/:productId"
          element={
            <>
              <Header isLoggedIn={isLoggedIn} />
              <EventDetail />
            </>
          }
        />
        <Route
          path="/network/:productId"
          element={
            <>
              <Header isLoggedIn={isLoggedIn} />
              <EventDetail />
            </>
          }
        />
        <Route
          path="/scholarship/:productId"
          element={
            <>
              <Header isLoggedIn={isLoggedIn} />
              <EventDetail />
            </>
          }
        />
        <Route
          path="/privacy"
          element={
            <>
              <Header isLoggedIn={isLoggedIn} />
              <Privacy />
            </>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRouter;
